import { SelectProps } from '@amzn/awsui-components-react';
import { ControllerProps } from 'react-hook-form';
import { OneOrList } from './reactInterfaces';

export type keysType = OneOrList<string>;

export enum AttributeEditorInput {
  Text = 'text',
  Code = 'code',
  Select = 'select',
}

// Make mutually exclusive interfaces/definitions depending on the given input type
type TextType = { input?: AttributeEditorInput.Text; language?: never; options?: never };
type SelectType = { input?: AttributeEditorInput.Select; language?: never; options: SelectProps.Options };
type CodeType = { input?: AttributeEditorInput.Code; language: string; options?: never; placeholder?: never };

/** A list of these definition objects can be passed to an `<AttribtueEditor>` component.
 * The component will use this definition to create the necessary definitions for the `<Table>` in `"view"` mode and the `<AttributeEditor>` in `"edit"` mode */
export type editorInputDefinition = {
  /** The label associated with a particular key of an item object */
  label: string;
  /** The key in the corresponding item object. DO NOT USE KEY="id" AS REACT-HOOK-FORM RESERVES THIS VALUE!  */
  key: string;
  /**
   * The type of input to render in the `<AttributeEditor definition>` control.
   *  - `text` creates an `<Input>` field (default)
   *  - `select` creates a `<Select>` field (and requires associated options)
   *  - `code` creates a custom `<CodeEditor>` field
   */
  input?: AttributeEditorInput;
  /** Required if `input` is `"select"`. A list of Select Options to render in the dropdown. */
  options?: SelectProps.Options;
  /** For when `input` is `"code"`, this is the language for syntax highlighting in both the [`<CodeEditor>`](../components/helpers/BetterCodeEditor.tsx) and [`<CodeTextBox>`](../components/helpers/CodeTextBox.tsx)  */
  language?: string;
  /** Placeholder text to display if the input is empty */
  placeholder?: string;
  /** Whether (and how) default values should be added to the attribute editor.
   *  - `boolean` : If `true`, will reuse the previously input value as the default value
   *  - `string` : A hard-coded string to use as the default value
   *  - `(allItems) => string` a function mapping from the total list of items to the default value
   *
   * See the [`editorAddWithDefault()`](../components/helpers/BetterCodeEditor.tsx) function for implementation. */
  defaults?: boolean | string | ((allItems: any[]) => string);
  /** A rules `react-hook-form` rules object to validate this specific field  */
  rules?: ControllerProps['rules'];
} & (TextType | SelectType | CodeType);
