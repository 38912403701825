import { KeyValuesList } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { useMemo } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { getNodeText } from 'src/commons';
import { DATA_TYPE, FIELD_NAME } from 'src/components/activity/load/LoadJSONContainer';
import { getServiceRegion } from 'src/constants/config';
import { isRedshiftDatabaseName, isRedshiftStandardId } from 'src/constants/redshiftConstants';

/** The Controller `rules` type used to validate a react-hook-form field */
export type RulesType<T> = UseControllerProps<T>['rules'];
/** The Content type which may either be a Rules object, or a function mapping a label to a rules object */
export type RulesProp<T> = RulesType<T> | ((label: string) => RulesType<T>);
/** A function which takes in a `props.rules` and applies a label to it, if it's a function */
export function useRules<T>(rules: RulesProp<T>, label: React.ReactNode, disabled?: boolean) {
  return useMemo(
    () => (disabled ? undefined : typeof rules === 'function' ? rules(getNodeText(label)) : rules),
    [rules, label, disabled],
  );
}

/** A validation function that sets a field as required thru `react-hook-form` rules
 *
 * @param {string} label Optional - The label of the field, which will replace "This field" in order to customize the error message
 * @returns an object with a value and useful message describing how the field is invalid
 *
 * @example
 * rules = { required : Rules.required(label) } */
export function required(label = 'This field') {
  return { value: true, message: `${label} is required.` };
}

/** A validation function that sets a minimum length for a text or array field thru `react-hook-form` rules
 *
 * @param {number} length The minimum length (inclusive) of the field's value
 * @param {string} label Optional - The label of the field, which will replace "This field" in order to customize the error message
 * @returns an object with a value and useful message describing how the field is invalid
 *
 * @example
 * rules = { minLength : Rules.minLength(1, label) } */
export function minLength(length: number, label = 'This field') {
  return { value: length, message: `${label} must have a length of at least ${length}.` };
}

/** A validation function that sets a maximum length for a string or array field thru `react-hook-form` rules
 *
 * @param {number} length The maximum length (inclusive) of the field's value
 * @param {string} label Optional - The label of the field, which will replace "This field" in order to customize the error message
 * @returns an object with a value and useful message describing how the field is invalid
 *
 * @example
 * rules = { maxLength : Rules.maxLength(9, label) } */
export function maxLength(length: number, label = 'This field') {
  return { value: length, message: `${label} may have a length of at most ${length}.` };
}

/** A validation function that sets a minimum value for a numerical field thru `react-hook-form` rules
 *
 * @param {number} value The minimum value (inclusive) of the field's value
 * @param {string} label Optional - The label of the field, which will replace "This field" in order to customize the error message
 * @returns an object with a value and useful message describing how the field is invalid
 *
 * @example
 * rules = { min : Rules.min(1, label) } */
export function min(value: number, label = 'This field') {
  return { value, message: `${label} must be at least ${value}.` };
}

/** A validation function that sets a maximum value for a numerical field thru `react-hook-form` rules
 *
 * @param {number} value The maximum value (inclusive) of the field's value
 * @param {string} label Optional - The label of the field, which will replace "This field" in order to customize the error message
 * @returns an object with a value and useful message describing how the field is invalid
 *
 * @example
 * rules = { max : Rules.max(9, label) } */
export function max(value: number, label = 'This field') {
  return { value, message: `${label} may be at most ${value}.` };
}

// Pattern Rules

const lettersNumbersHyphenUnderscoreRe = /^[a-zA-Z0-9_-]*$/;
function lettersNumbersHyphenUnderscore(label = 'This field') {
  return {
    value: lettersNumbersHyphenUnderscoreRe,
    message: `${label} may only contain letters, numbers, hyphens and underscores.`,
  };
}

/** IAM role arn pattern taken from https://w.amazon.com/bin/view/AWS/Teams/Proserve/PxPS/Honeycomb/securitycontrols/regex-patterns/#HIAMRoleARN */
const iamRoleArnRe = /^arn:aws[a-z0-9\-]*:iam::\d{12}:role\/[\w\-\/.@+=,]{1,1017}$/;
function iamRoleArn(label = 'This field') {
  return {
    value: iamRoleArnRe,
    message: `${label} must be a valid IAM role ARN.`,
  };
}

/** Topic ARN pattern taken from https://w.amazon.com/bin/view/AWS/Teams/Proserve/PxPS/Honeycomb/securitycontrols/regex-patterns/#HSNSTopicARN */
const snsTopicArnRe = /^arn:aws[a-z0-9\-]*:sns:[a-z0-9\-]+:\d{12}:[\w\-]{1,256}$/;
function snsTopicArn(label = 'This field') {
  return { value: snsTopicArnRe, message: `${label} must be a valid SNS topic ARN.` };
}

// This matches any s3 path, which must start with "s3://" ; this will not match S3 ARNs (https://docs.aws.amazon.com/AmazonS3/latest/userguide/s3-arn-format.html)
const s3PathRe = /^s3:\/\/.+$/;
function s3Path(label = 'This field') {
  return { value: s3PathRe, message: `${label} must be a valid S3 path.` };
}

// Field delimiter validation from old Ruby site: https://code.amazon.com/packages/AWSDWHammerstoneWebsite/blobs/b123c662f86e0dce22ca8beca642664e5fbd664e/--/rails-root/app/assets/javascripts/subscriptions/new.js#L55-L66
// Rules based on https://docs.aws.amazon.com/redshift/latest/dg/copy-parameters-data-format.html#copy-delimiter
const fieldDelimiterRe = /^(.|\\t|\\[0-7]{3})$/;
function fieldDelimiter(label = 'This field') {
  return {
    value: fieldDelimiterRe,
    message: `${label} must be either empty, a single char, a tab "\\t", or an octal "\\ddd".`,
  };
}

// The Hammerstone API Model has the following MaterialSet interface/pattern https://code.amazon.com/packages/AWSHammerstoneExposedRESTfulServiceLambdaModel/blobs/e0f9e904740ccdc1dabf63e2abb89791d914c5dd/--/model/Datatypes.xml#L105-L108
// The above regex is (likely) incorrect, however,  since it uses A-z which would include [\]^` which I have not found included in any Odins
// The regex below is inferred from the odin website, it enforces alphanumeric characters or -_ with at LEAST 2 intermediate periods (creates a 3+ level odin)
const materialSetRe = /^([A-Za-z0-9-_]+\.){2,}[A-Za-z0-9-_]*[^.]$/;
function materialSet(label = 'This field') {
  return {
    value: materialSetRe,
    message: `${label} must be a valid Odin material set, consisting of at most 255 alphanumeric characters, dashes, underscores, or periods. It should be at least three levels (2 periods) deep.`,
  };
}

/** An object containing validation functions with a value and message for various regex patterns.
 *
 * Each function should have an optional, final `label` argument, which will replace "This field" in order to customize the error message
 *
 * @example
 * rules = { pattern : Rules.pattern.patternName(label) }
 */
export const pattern = {
  lettersNumbersHyphenUnderscore,
  iamRoleArn,
  snsTopicArn,
  s3Path,
  fieldDelimiter,
  materialSet,
};

// Custom Validation Rules

/** A custom validation rule which ensures that a field contains a valid Redshift database name.
 * See [`isRedshiftDatabaseName()`](../../../constants/redshiftConstants.tsx) for full documentation
 * Naming constraints: https://docs.aws.amazon.com/redshift/latest/mgmt/amazon-redshift-limits.html
 *
 * @param {string} label Optional - The label of the field, which will replace "This field" in order to customize the error message
 *
 * @example
 * rules = { validate: { redshiftDb: Rules.redshiftDatabseName(label) } } */
export function redshiftDatabaseName(label = 'This field') {
  return (value: string) =>
    isRedshiftDatabaseName(value) ||
    `${label} must be a Redshift database name: it must contain 1-64 lowercase alphanumeric characters, underscores, or hyphens and may not be a Redshift reserved keyword.`;
}

/** A custom validation rule which ensures that a field contains a valid Redshift standard identifier (e.g. schema, table, column names)
 * See [`isRedshiftStandardId()`](../../../constants/redshiftConstants.tsx) for full documentation
 * "Standard Identifiers": https://docs.aws.amazon.com/redshift/latest/dg/r_names.html
 *
 * @param {string} label Optional - The label of the field, which will replace "This field" in order to customize the error message
 *
 * @example
 * rules = { validate: { redshiftId: Rules.redshiftStandardId(label) } } */
export function redshiftStandardId(label = 'This field') {
  return (value: string) =>
    isRedshiftStandardId(value) ||
    `${label} must contain only lowercase alphanumeric characters, underscores, or dollar signs and may not be a Redshift reserved keyword.`;
}

/** Checks whether a value is NaN */
export function isNumber(label = 'This field') {
  return (value: number) => !isNaN(value) || `${label} must be a valid number.`;
}

/** Checks whether a value is an integer (no decimal places allowed) */
export function isInteger(label = 'This field') {
  return (value: string) =>
    Number.isInteger(parseFloat(value)) || `${label} must be an integer, no decimal places allowed.`;
}

/** Confirms whether a string can be parsed to a valid JSON SOURCE_DEFINITION for Load Activities.
 * Rule is defined following information in https://w.amazon.com/bin/view/Hammerstone_json_help/ */
export function isJsonSourceDefinition(label = 'This field') {
  // Function is made asynchronous to minimize performance impact of validating on every keystroke
  return async (value: string) => {
    try {
      const parsed = JSON.parse(value);
      if (typeof parsed !== 'object' || Array.isArray(parsed)) {
        // JSON.parse can return a string, number, boolean, area, object, etc., so this check ensures that it is a {}-style object
        return `${label} must be a JSON key-value pair object.`;
      }
      const keyObjectsList: KeyValuesList[] = parsed.keyObjectsList;

      if (!keyObjectsList || !Array.isArray(keyObjectsList)) {
        return `${label} must contain a "keyObjectsList" corresponding to a list of key-value list definitions.`;
      }
      // Initially designed as a series of checks, but this can be cumbersome as it requires repeated iterations over the object
      for (const [listIx, keyValueList] of keyObjectsList.entries()) {
        let HAS_DATA_TYPE = false;
        let HAS_FIELD_NAME = false;
        for (const [objIx, kevValueObj] of keyValueList.entries()) {
          const key = kevValueObj['key'];
          const value = kevValueObj['value'];
          if (!key) {
            return `${label} must be a list of valid key-value list definitions. The object at [${listIx}][${objIx}] is missing a key member.`;
          }
          if (!value) {
            return `${label} must be a list of valid key-value list definitions. The object at [${listIx}][${objIx}] is missing a value member.`;
          }

          if (key === DATA_TYPE) {
            HAS_DATA_TYPE = true;
            // The commented-out code below would warn a user that the provided Redshift datatypes are invalid. To avoid beingly overly stringent or out-of-date with Redshift nomenclature, this validation is currently removed.
            // We are now being lenient to avoid customer frustration if the Redshift implementation changes. We HAD been more stringent to provide a more guided hammerstone user experience initially
            // Newer customers who are not familiar with this highly opaque/idiosyncratic format could use more clear error messages and guidance.
            //  If customers start causing failures in their jobs due to incorrect data-types, we may then consider adding additional frontend validation to prevent that

            // if (!ALL_REDSHIFT_DATA_TYPES.has(value)) {
            //   return `${label} is invalid. The DATA_TYPE at [${listIx}][${objIx}] ("${value.toString()}") is not supported by Redshift`;
            // }
          }
          if (kevValueObj['key'] === FIELD_NAME) {
            HAS_FIELD_NAME = true;
          }
        }
        if (!HAS_DATA_TYPE) {
          return `${label} is missing a key:"DATA_TYPE" in definition [${listIx}].`;
        }
        if (!HAS_FIELD_NAME) {
          return `${label} is missing a key:"FIELD_NAME" in definition [${listIx}].`;
        }
      }
    } catch {
      return `${label} must be parseable as a valid JSON object.`;
    }
    return true;
  };
}

/** The prefix for a regex that accepts any standard email identifier */
const prefix = '^[A-Za-z0-9-_]+@(';
/** The suffix for a regex that accepts a standard email identifier */
const suffix = ')$';
// To combine the above prefix and suffix, we need an infix which is a pipe-separated (regex disjunction) of email domains
// TODO: are there any non-.com domains for amazon? e.g. cn, fr, it, etc.
const amzDomain = 'amazon.com';
const zhyDomain = 'nwcdcloud.cn';
const dcaDomain = 'c2s.ic.gov';
const lckDomain = 'sc2s.sgov.gov';

/**
 * Creates a dynamic email regex depending on the current region.
 * Currently, our customers are either amazon-internal (@amazon.com),
 *   - Employees of nwcdcloud.cn in cn-northwest-1 (ZHY)
 *   - Cleared engineers in MVP regions
 *     - @c2s.ic.gov in aws-iso (DCA)
 *     - @sc2s.sgov.gov in aws-isob (LCK)
 * */
function getHammerstoneEmailRe() {
  const region = getServiceRegion();
  switch (region) {
    case 'cn-northwest-1': // ZHY
      return new RegExp(prefix + [amzDomain, zhyDomain].join('|') + suffix);
    case 'us-iso-east-1': // DCA
      return new RegExp(prefix + [amzDomain, dcaDomain].join('|') + suffix);
    case 'us-isob-east-1': // LCK
      return new RegExp(prefix + [amzDomain, lckDomain].join('|') + suffix);
    default: // Default is only amazon domains
      return new RegExp(prefix + amzDomain + suffix);
  }
}
/** Whether a string is a valid comma-separated list of valid emails */
export function isHammerstoneEmailList(label = 'This field') {
  const hammerstoneEmailRe = getHammerstoneEmailRe();

  const message = `${label} must be a comma-separated list of valid email addresses (no spaces).`;
  return (value: string) => {
    const emails = (value ?? '').split(',');
    const isValid = emails.every((email) => hammerstoneEmailRe.test(email));
    return isValid ? true : message;
  };
}
