import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  ButtonDropdown,
  CollectionPreferences,
  Header,
  Icon,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PAGES from '../../nav/Pages';
import { urlSearchString } from '../../nav/navHelper';
import { fetchPipelineNames } from '../../data/api/fetchFromAPI';
import ManagePipelinesRow from './ManagePipelinesRow';
import { EmptyState, HammerstoneAppLayout, RefreshButton, LinkButton } from '../helpers';
import { actions, useGroupName, usePipelines } from 'src/data/redux';
import PipelineActionsDropdown from './PipelineActionsDropdown';
import PipelineToggleStatusButton from './PipelineToggleStatusButton';
import { getOldHammerstoneEndpoint } from 'src/constants/config';
import { useSearchParams } from 'react-router-dom';
import { useConditionalEffect } from 'src/commons';

const FILTER_SEARCH_PARAM = 'filterPipelines';

export default function ManagePipelines() {
  const groupName = useGroupName();
  const [searchParams, setSearchParams] = useSearchParams();

  useConditionalEffect(() => {
    fetchPipelineNames({ groupName });
  }, [groupName]);

  const dispatch = useDispatch();
  const { data: pipelineNames, fetching } = usePipelines(groupName);
  const loading = fetching !== false;
  const allItems = (pipelineNames ?? []).map((x) => ({ pipelineName: x }));
  const [selectedPipeline, setSelectedPipeline] = useState<string>('');
  const oldHSEndpoint = getOldHammerstoneEndpoint();

  const [preferences, setPreferences] = useState<any>({ pageSize: 15 });
  // A 'useful' Polaris hook for defining table properties: https://polaris.a2z.com/get-started/dev-guides/collection-hooks/
  const { items, paginationProps, collectionProps, filterProps } = useCollection(allItems, {
    filtering: {
      defaultFilteringText: searchParams.get(FILTER_SEARCH_PARAM),
      empty: <EmptyState title="No pipelines" subtitle="There were no pipelines found for this group." />,
      noMatch: <EmptyState title="No matches" subtitle={`No pipelines matched the filter criterion.`} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  return (
    <HammerstoneAppLayout {...PAGES.MANAGE_PIPELINES.pageLayoutProps({ groupName })}>
      <SpaceBetween size="xl">
        <Header
          variant="h1"
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <RefreshButton
                refreshing={loading}
                onRefresh={() => {
                  //Clears the pipelines before fetching the list of names so that each row will re-fetch its PipelineInfo
                  setSelectedPipeline('');
                  dispatch(actions.data.clearData({ datatype: 'pipeline', dataId: pipelineNames }));
                  fetchPipelineNames({ groupName }, true);
                }}
              />
              <ButtonDropdown
                onItemClick={(e) => {
                  e.preventDefault();
                  // Instead of adding an 'external' to each of the items (visual clutter), I added an external icon to the top-level button and intercept the navigation to open in a new window.
                  window.open(e.detail.href, '_blank');
                }}
                items={[
                  {
                    id: 'globalSearch',
                    text: 'Search shared pipelines',
                    href: `${oldHSEndpoint}/globalsearch?groupName=${groupName}`,
                  },
                  {
                    id: 'export',
                    text: 'Export pipelines and activities',
                    href: `${oldHSEndpoint}/importexport?groupName=${groupName}`,
                  },
                  {
                    id: 'import',
                    // Parenthetical included to inform customers that this link does not lead directly to the desired functionality, but rather that they have click on the Import button on the old Manage Pipelines page
                    text: 'Import activities (old Manage Pipelines page)',
                    href: `${oldHSEndpoint}/new?groupName=${groupName}`,
                    description: 'Go to old Manage Pipelines page to access',
                  },
                ]}
              >
                Operations <Icon name="external" />
              </ButtonDropdown>
              <LinkButton
                variant="primary"
                iconName="add-plus"
                data-testid="createPipelineLinkButton"
                href={PAGES.CREATE_PIPELINE.path + urlSearchString({ groupName })}
              >
                Create pipeline
              </LinkButton>
            </SpaceBetween>
          }
        >
          Manage Pipelines
        </Header>
        <Table
          {...collectionProps}
          data-testid="managePipelinesTable"
          header={
            <Header
              variant="h2"
              data-testid="managePipelinesHeader"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <PipelineToggleStatusButton pipelineName={selectedPipeline} />
                  <PipelineActionsDropdown pipelineName={selectedPipeline} />
                </SpaceBetween>
              }
              counter={!loading && `(${pipelineNames.length})`}
            >
              Pipelines
            </Header>
          }
          wrapLines={true}
          columnDefinitions={[
            {
              id: 'pipeline',
              header: 'Pipeline',
              sortingField: 'pipelineName',
              cell: (item) => (
                <ManagePipelinesRow
                  pipelineName={item.pipelineName}
                  selected={item.pipelineName === selectedPipeline}
                  groupName={groupName}
                />
              ),
            },
          ]}
          items={items}
          loading={loading}
          loadingText="Loading pipelines"
          pagination={
            <Pagination
              data-testid="managePipelinesPagination"
              ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
              }}
              {...paginationProps}
            />
          }
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={{
                title: 'Page size',
                // Keeping the max number of pipelines small to avoid too many failed API requests on a page
                options: [15, 25, 50].map((value) => ({ value, label: value + ' Pipelines' })),
              }}
            />
          }
          filter={
            <TextFilter
              data-testid="managePipelinesTextFilter"
              filteringAriaLabel="Filter instances"
              filteringPlaceholder="Filter pipelines by name"
              {...filterProps}
              onDelayedChange={({ detail }) =>
                setSearchParams((prevParams) => {
                  prevParams.set(FILTER_SEARCH_PARAM, detail.filteringText);
                  return prevParams;
                })
              }
            />
          }
          selectionType="single"
          trackBy="pipelineName"
          selectedItems={[{ pipelineName: selectedPipeline }]}
          onSelectionChange={({ detail }) => setSelectedPipeline(detail.selectedItems[0].pipelineName)}
          onRowClick={({ detail }) => setSelectedPipeline(detail.item.pipelineName)}
        />
      </SpaceBetween>
    </HammerstoneAppLayout>
  );
}
