import { Box, Button, Link } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import Activity, { ActivityContainerProps } from '../../../interfaces/activityInterfaces';
import { ColumnContainer, Column } from '../../helpers';
import { useFormField } from 'src/data/redux';
import { JSON_HELP_WIKI } from 'src/constants/staticUrls';
import { valueListToOptions } from 'src/commons';
import Content, { Rules } from 'src/components/helpers/content/';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';
import { KeyValuesList } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import keys from 'src/constants/hammerstoneConstantKeys';

const canFormatJSON = (jsonString: string) => {
  try {
    const obj = JSON.parse(jsonString);
    return obj && typeof obj === 'object';
  } catch (error) {
    return false;
  }
};
const JSON_PATH = 'config.SOURCE_DEFINITION' as const;
export const FIELD_NAME = 'FIELD_NAME' as const;
export const DATA_TYPE = 'DATA_TYPE' as const;

/** Takes in a source definition JSON string and a particular key, and returns a list of all the values of that key in the definition. */
export function filterSourceDefinitionsByKey(jsonString: string, filterOnKey: string) {
  try {
    // Try parsing and retrieve the keyObjectsList child
    const keyValuesList: KeyValuesList[] = JSON.parse(jsonString).keyObjectsList;
    const distributionKeys = keyValuesList
      .map((values) => {
        // For each definition, get the first listed FIELD_NAME value
        const [keyName] = values.filter(({ key }) => key === filterOnKey).map(({ value }) => value);
        return keyName;
      })
      // Filter out all undefined and empty values
      .filter((keyName) => keyName);
    return distributionKeys;
  } catch {
    return [];
  }
}

// TODO: Eventually rewrite JSON Container with input from customers & update information on JSON help wiki
/**
 * An editable container for a LOAD/COPY activity's load JSON definition configuration
 */
export default function LoadJSONContainer(props: ActivityContainerProps) {
  const contentProps = { mode: props.mode, resourceType: 'activity', resourceId: props.activityId } as const;

  const jsonValue = useFormField<Activity>(JSON_PATH);
  const fieldNames = useMemo(() => filterSourceDefinitionsByKey(jsonValue, FIELD_NAME), [jsonValue]);

  return (
    <ColumnContainer
      header="Load JSON configuration"
      columns={2}
      actions={
        <Link variant="info" target="_blank" href={JSON_HELP_WIKI}>
          Info
        </Link>
      }
    >
      <Column>
        <Content.Code
          language="json"
          label="JSON source definition"
          missingText="no json definition given"
          path={JSON_PATH}
          // TODO: Add useful infoHelpPanel link and editDescription with constraints
          rules={(l) => ({
            required: Rules.required(l),
            validate: {
              isJson: Rules.isJsonSourceDefinition(l),
            },
          })}
          {...contentProps}
        />
        {props.mode === DisplayMode.Edit && <JSONFormatButton value={jsonValue} />}
      </Column>

      <Column>
        <Content.Select
          label="Distribution key"
          path="config.DIST_KEY_NAME"
          options={valueListToOptions(fieldNames)}
          rules={(l) => ({
            required: Rules.required(l),
            validate: {
              atLeastOneOption: () =>
                fieldNames?.length > 0 ||
                `You must select the distribution key from a list of options, which are defined by a valid JSON source definition with at least one object. `,
              optionInJson: (value: string) =>
                fieldNames.includes(value)
                  ? true
                  : `You must select a distribution key that is included in the JSON source definition.`,
            },
          })}
          {...contentProps}
        />
        <Content.Multiselect
          label="Merge key(s)"
          path="config.MERGE_KEY_FIELD"
          options={valueListToOptions(fieldNames)}
          disableOn={{
            paths: ['config.DATA_REPLACE_OPTION'],
            condition: (activity) => activity.config.DATA_REPLACE_OPTION !== keys.LoadReplaceOption.UPSERT,
          }}
          rules={(l) => ({
            required: Rules.required(l),
            minLength: Rules.minLength(1, l),
            validate: {
              atLeastOneOption: () =>
                fieldNames.length > 0 ||
                `You must select the distribution key from a list of options, which are defined by a valid JSON source definition with at least one object. `,
              optionsInJson: (values: string[]) =>
                values.every((value) => fieldNames.includes(value))
                  ? true
                  : `You may only select merge keys that are included in the JSON source definition.`,
            },
          })}
          {...contentProps}
        />
      </Column>
    </ColumnContainer>
  );
}

function JSONFormatButton(props: { value: string }) {
  let form: UseFormReturn<Activity>;
  try {
    form = useFormContext<Activity>();
  } catch {}

  return (
    <Box float="right">
      <Button
        data-testid="Format_json"
        disabled={!form || !canFormatJSON(props.value)}
        onClick={(e) => {
          e.preventDefault();
          if (form) {
            // Make sure that the form was correctly retrieved from the context above before trying to call a method
            form.setValue(JSON_PATH, JSON.stringify(JSON.parse(props.value), null, 2));
          }
        }}
      >
        Format JSON
      </Button>
    </Box>
  );
}
