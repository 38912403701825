import React, { useCallback, useEffect } from 'react';
import { Button, DatePicker, FormField, SpaceBetween, TimeInput } from '@amzn/awsui-components-react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { useEditOnChange, useIsEditContentDisabled, useViewContent } from './contentHelpers';
import { DisplayMode, ResourcePath, ResourceType, iContent } from './contentInterfaces';
import { LabeledContentSkeleton } from '../skeletons/LabeledContentSkeleton';
import { HelpPanelInfoLink, LabeledContent, ToggleableDatetime } from '..';
import { datePicker_ariaLabels } from 'src/constants/ariaLabels';
import { DataAttributes, filterDataAttributes, getContentDataAttributes } from 'src/commons/dataAttributes';
import { ContentErrorWrapper } from 'src/components/error/ContentErrorBoundary';
import { DateLike } from 'src/interfaces/reactInterfaces';
import { Rules } from '.';
import { dateToApiString, getNodeText } from 'src/commons';

/** Renders the field identified by the `path` as a Polaris [`<DatePicker>`](https://cloudscape.aws.dev/components/date-picker/) and [`<TimeInput>`](https://cloudscape.aws.dev/components/time-input/) component. Manipulates the value as a `string` type
 * For an overview of `<Content>` components, see the [README.md](./README.md). For usage examples, see the [How-to guide](./HowToGuide.md) */
export const DatetimeContent = ContentErrorWrapper(_DatetimeContent);

function _DatetimeContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths>,
) {
  // Processed at top level component to avoid unneccessary executions of a relatively heavy functions between renders
  const dataAttributes = { ...filterDataAttributes(props), ...getContentDataAttributes('Datetime', props) };

  switch (props.mode) {
    case DisplayMode.View:
      return <ViewDatetimeContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Edit:
      return <EditDatetimeContent {...props} dataAttributes={dataAttributes} />;
    case DisplayMode.Loading:
      return <LabeledContentSkeleton label={props.label} />;
    default:
      return null;
  }
}

/** By default, datetimes are displayed using the `<ToggleableDatetime>` component */
function defaultDatetimeViewTransform(value: DateLike) {
  return <ToggleableDatetime>{value}</ToggleableDatetime>;
}

function ViewDatetimeContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { value, disabled } = useViewContent(props);
  return (
    <LabeledContent
      label={props.label}
      info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
      missingText={props.missingText}
    >
      <span className={disabled ? 'disabled-content' : ''} {...props.dataAttributes}>
        {props.viewTransform ? props.viewTransform(value) : defaultDatetimeViewTransform(value)}
      </span>
    </LabeledContent>
  );
}

function EditDatetimeContent<RType extends keyof ResourceType, DisabledOnPaths extends ResourcePath<RType>[]>(
  props: iContent<RType, DisabledOnPaths> & { dataAttributes: DataAttributes },
) {
  const { control, clearErrors, setValue } = useFormContext<ResourceType[RType]>();
  const disabled = useIsEditContentDisabled(props);

  return (
    <Controller
      control={control}
      name={props.path}
      rules={Rules.useRules(props.rules, props.label, disabled)}
      render={({ field, formState, fieldState }) => {
        const onChange = useEditOnChange(props, field.onChange, setValue, 'value');

        useEffect(() => {
          // This effect will ignore any potential validation errors if the particular field is disabled
          if (disabled) {
            clearErrors(props.path);
          }
        }, [disabled, formState.isSubmitting, formState.isValidating]);

        // If the field value is not defined, then the date and time values will be blank
        const [dateValue, timeValue] = ((field.value as string) ?? ' ').split(' ');
        // Set default placeholders describing the correct UTC date format
        const [datePlaceholder, timePlaceholder] = (props.placeholder ?? 'YYYY/MM/DD hh:mm:ss').split(' ');

        // Uses onCallback to avoid unnecessary computations between renders
        const dateOnChange = useCallback(
          (e: CustomEvent) => {
            // Intercepts the event to format the new date value and keep the old time value
            // Slash-separated date strings are interpreted as LOCALE time by JS Date objects, while dash-separated are interpreted as UTC
            // The DatePicker component unfortunately returns slash-separated date, so this is converted to prevent errors
            e.detail.value = `${e.detail.value.replaceAll('/', '-')} ${timeValue}`;
            return onChange(e);
          },
          [timeValue],
        );
        const timeOnChange = useCallback(
          (e: CustomEvent) => {
            // Intercepts the event to keep the old date value and update the new time value
            e.detail.value = `${dateValue} ${e.detail.value}`;
            return onChange(e);
          },
          [dateValue],
        );

        return (
          <FormField
            label={props.label}
            description={props.editDescription}
            info={props.infoHelpPanel && <HelpPanelInfoLink helpPanel={props.infoHelpPanel} />}
            errorText={get(formState, `errors.${props.path}.message`)}
          >
            <span {...props.dataAttributes}>
              <SpaceBetween size="s" direction="horizontal">
                <span className="date-input">
                  <DatePicker
                    {...datePicker_ariaLabels}
                    data-testid={getNodeText(props.label) + '_date_picker'}
                    onChange={dateOnChange}
                    value={dateValue}
                    placeholder={datePlaceholder}
                    controlId={`Date for ${props.label}`}
                    disabled={disabled}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    invalid={fieldState.invalid}
                  />
                </span>
                <span className="time-input">
                  <TimeInput
                    data-testid={getNodeText(props.label) + '_time_input'}
                    placeholder={timePlaceholder}
                    onChange={timeOnChange}
                    value={timeValue}
                    format="hh:mm:ss"
                    controlId={`Time for ${props.label}`}
                    disabled={disabled}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    invalid={fieldState.invalid}
                  />
                </span>
                <span className="today-button">
                  <Button
                    onClick={() => onChange({ detail: { value: dateToApiString(new Date()) } } as CustomEvent)}
                    variant="normal"
                  >
                    Today
                  </Button>
                </span>
              </SpaceBetween>
            </span>
          </FormField>
        );
      }}
    />
  );
}
