import { SpaceBetween, ColumnLayout } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';

import keys from '../../constants/hammerstoneConstantKeys';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import ActivityLoadingSkeleton from './ActivityLoadingSkeleton';
import AboutContainer from './all/AboutContainer';
import NotificationsContainer from './all/NotificationsContainer';
import PreconditionsContainer from './all/PreconditionsContainer';
import ScheduleContainer from './all/ScheduleContainer';
import ExtractTransformS3Container from './all/ExtractTransformS3Container';
import ExtractSQLContainer from './extract/ExtractSQLContainer';
import LoadConfigContainer from './load/LoadConfigContainer';
import LoadJSONContainer from './load/LoadJSONContainer';
import LoadS3Container from './load/LoadS3Container';
import LoadSQLContainer from './load/LoadSQLContainer';
import TransformAuditorContainer from './transform/TransformAuditorContainer';
import TransformConfigContainer from './transform/TransformConfigContainer';
import TransformSQLContainer from './transform/TransformSQLContainer';
import ErrorLayout from '../error/ErrorLayout';
import ErrorCannotCreateOdinActivity from '../error/ErrorCannotCreateOdinActivity';
import { s3CredentialTypeSelector, useActivity, useFormField } from 'src/data/redux';
import Activity from 'src/interfaces/activityInterfaces';

export default function ActivityContainerLayout(props: {
  mode: DisplayMode;
  activityId: string;
  activityType?: string;
}) {
  const { activityType } = props;
  const containerProps = {
    mode: props.mode,
    activityId: props.activityId,
  };

  const { data: activity, fetching, hasFetched } = useActivity(props.activityId);

  const pipelineName = useFormField<Activity>('activityNamespace');
  const s3CredentialType = useSelector(s3CredentialTypeSelector(pipelineName));

  const loading = typeof hasFetched === 'undefined' || fetching;

  // When creating a new resource (e.g. Activity), include a blank/undefined dataId
  const creating = !props.activityId;
  const failedToLoad = !fetching && !hasFetched && typeof activity === 'undefined';

  if (loading && !creating) {
    return <ActivityLoadingSkeleton />;
  } else if (!creating && failedToLoad) {
    return (
      <ErrorLayout header="Failed to fetch activity" backtrackButtons>
        We apologize for the inconvenience, but there was an error while fetching activity {props.activityId}. <br />
        You may want to return to either of the following:
      </ErrorLayout>
    );
  } else {
    return (
      <SpaceBetween size="xl">
        <AboutContainer header="About" {...containerProps} mode={props.mode} />

        {creating && s3CredentialType === 'ODIN' ? (
          // Users should not create or copy activities in Odin pipelines
          // If they attempt to, they will see this error instead of the activity form fields
          <ErrorCannotCreateOdinActivity pipelineName={pipelineName} />
        ) : (
          <>
            <ColumnLayout columns={props.mode === 'view' ? 2 : undefined}>
              <ScheduleContainer header="Schedule" {...containerProps} />
              <NotificationsContainer header="Notifications" {...containerProps} />
            </ColumnLayout>
            <PreconditionsContainer header="Preconditions" {...containerProps} />

            {activityType === keys.ActivityType.EXTRACT && (
              <SpaceBetween size="xl">
                <ExtractTransformS3Container header="Extract S3" activityType="EXTRACT" {...containerProps} />
                <ExtractSQLContainer header="Extract SQL" {...containerProps} />
              </SpaceBetween>
            )}

            {activityType === keys.ActivityType.TRANSFORM && (
              <SpaceBetween size="xl">
                <ExtractTransformS3Container header="Transform S3" activityType="TRANSFORM" {...containerProps} />
                <TransformConfigContainer header="Transform configuration" {...containerProps} />
                <TransformSQLContainer header="Transform SQL" {...containerProps} />
                <TransformAuditorContainer header="Auditor" {...containerProps} />
              </SpaceBetween>
            )}

            {activityType === keys.ActivityType.COPY && (
              <SpaceBetween size="xl">
                <LoadS3Container header="Load S3" {...containerProps} />
                <LoadConfigContainer header="Load configuration" {...containerProps} />
                <LoadSQLContainer header="Load SQL" {...containerProps} />
                <LoadJSONContainer header="Load JSON" {...containerProps} />
              </SpaceBetween>
            )}
          </>
        )}
      </SpaceBetween>
    );
  }
}
