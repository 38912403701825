import {
  CreateActivityInfoBody,
  CreateActivityInfoRequest,
  PutActivityInfoBody,
  PutActivityInfoRequest,
} from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { Button } from '@amzn/awsui-components-react';
import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import PAGES from 'src/nav/Pages';
import { urlSearchString } from 'src/nav/navHelper';
import Activity from 'src/interfaces/activityInterfaces';
import { activityFormToCreateBody, activityFormToUpdateBody } from 'src/data/postprocessors/activityPostprocessor';
import { CreateActivityInfo, PutActivityInfo } from 'src/lib/hammerstoneApi';
import { actions, useAlias, useGroupName } from 'src/data/redux';
import { ConfirmActionModal, tempFlashbarMessage } from '../helpers';
import { fetchActivityInfo, fetchPipelineInfo } from 'src/data/api/fetchFromAPI';
import keys from 'src/constants/hammerstoneConstantKeys';
import RevisionCommentField from '../helpers/RevisionCommentField';
import { getServiceRegion } from 'src/constants/config';
import { ActivityFormActions } from 'src/constants/hammerstoneConstants';

const ACTIVITY_FORM_FLASHBAR_ID = 'activity_form_page';

type ActionType = keyof typeof ActivityFormActions; // 'create' | 'copy' | 'edit'
const { edit, copy, create } = keys.ActivityFormActions;

/** Calls Hammerstone API's `PutActivityInfo` if editing an existing activity, otherwise calls `PostActivityInfo` to create a new one.
 * Returns the activity ID of the new/edited activity. */
async function CallActivityApi(
  action: ActionType,
  activityForm: Activity,
  alias: string,
  groupName: string,
  activityId?: string,
): Promise<number> {
  if (action === edit) {
    // Converting the activity edit fields to PutActivityInfoBody (HS API)
    const body: PutActivityInfoBody = activityFormToUpdateBody(activityForm, alias);
    // Call API to update activity
    const request: PutActivityInfoRequest = {
      activityId: parseInt(activityId),
      groupName,
      body,
    };
    const output = await PutActivityInfo(request);
    return output.activityId;
  } else {
    // Converting the activity fields to PostActivityInfoBody (HS API)
    const body: CreateActivityInfoBody = activityFormToCreateBody(activityForm, alias);
    const request: CreateActivityInfoRequest = { groupName, body };
    // Call API to create new activity
    const output = await CreateActivityInfo(request);
    return output.activityId;
  }
}

/** Defines custom success messages for each activity form submission type */
function successMessage(action: ActionType, newActivityId: string, oldActivityId?: string) {
  switch (action) {
    case copy:
      return `Successfully copied activity ${oldActivityId} to ${newActivityId}`;
    case create:
      return `Successfully created activity ${newActivityId}`;
    case edit:
      return `Successfully updated activity ${newActivityId}`;
  }
}

/** A powerful hook which can be called at the top of an Activity Form page.
 * This hook initializes all the relevant states & callbacks for the activity form page
 * and also handles form submission, successes, and failures.
 *
 * @param action Whether the current page is to create, copy, or edit an activity
 * @param activityId - Optional , the activity Id if editing or copying
 *
 * @returns An object with the following states and callbacks (see each member's documentation for more details):
 * - form : `react-hook-form::Form`
 * - onCancel : `() => void`
 * - onValidSubmit : `(activity: Activity) => Promise<void>`
 * - ActivateButton : `JSX.Element`
 * - groupName : `string`
 * - alias : `string`
 * - comment : `string`
 * - setComment : `SetState<string>`
 * - isModalVisible: `boolean`
 * - setIsModalVisible : `SetState<boolean>`
 * - searchParams : `URLSearchParams`
 * - setSearchParams : `SetURLSearchParams`
 * - navigate : `react-router::Navigate`
 * - dispatch : `react-redux::Dispatch`
 *  */
export function useActivityForm(action: ActionType, activityId?: string) {
  // Subscribe to redux store
  const groupName = useGroupName();
  const alias = useAlias();

  // Call functional hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Create necessary states:
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comment, setComment] = useState('');

  /** The default values to populate when creating a new activity.  */
  const DEFAULT_NEW_ACTIVITY_FORM_VALUES = {
    activityNamespace: searchParams.get('pipelineName'),
    activityRetryMaximum: 2,
    activityRetryDelay: 5,
    s3PreconRegion: (getServiceRegion() as keyof typeof keys.Region) ?? 'us-east-1',
    scheduleDateOffset: 0,
    config: {
      ADD_ACL: true,
      DJS_ALARM_ALL_EXECUTIONS_FAILED: true,
      DATE_FORMAT: 'YYYY-MM-DD',
      PRECON_RETRY: 1,
      PRECON_WAIT: 0,
      TIME_FORMAT: 'YYYY-MM-DD HH:MI:SS',
      // LOAD-specific default values
      DATA_REPLACE_OPTION: 'INSERT',
      MAX_ERROR: 0,
      SKIP_LINES: 0,
      SOURCE_TYPE: 'FILE',
      FILE_FORMAT: 'DELIMITER',
    },
  };

  /** Creates a form object for an Activity form:
   * https://react-hook-form.com/docs/useform */
  const form = useForm<Activity>({
    defaultValues: action === create ? DEFAULT_NEW_ACTIVITY_FORM_VALUES : {},
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  /** Spread a list to reduce redundancy and make code more easily refactorable:
   * This is a list of the identifiers which are dependencies for the onCallback hooks below */
  const idDependencies = [alias, groupName, activityId];

  // === CREATE FORM CALLBACK FUNCTIONS ===

  /** On cancelling the activity form, return to existing activity (edit, copy) or to the parent pipeline page (create) */
  const onCancel = useCallback(() => {
    const path = action === create ? PAGES.VIEW_PIPELINE.path : PAGES.VIEW_ACTIVITY.path;
    navigate({
      // Replaces both activityId and pipelineName naively
      pathname: path.replace(':activityId', activityId).replace(':pipelineName', searchParams.get('pipelineName')),
      search: urlSearchString({ groupName }),
    });
  }, []);

  /** Upon the succesful submission of a PUT/POST ActivityInfo call, navigate to the touched activity */
  const onSuccess_Navigate = useCallback(
    async (newActivityId: number, activity: Activity) => {
      tempFlashbarMessage({
        id: ACTIVITY_FORM_FLASHBAR_ID,
        message: { header: successMessage(action, newActivityId.toString(), activityId) },
      });
      // Refresh activity info after update
      fetchActivityInfo({ groupName, activityId: newActivityId }, true);
      // Refresh activity's pipeline info after update
      fetchPipelineInfo({ groupName, pipelineName: activity.activityNamespace }, true);
      navigate({
        pathname: PAGES.VIEW_ACTIVITY.path.replace(':activityId', newActivityId.toString()),
        search: urlSearchString({ groupName }),
      });
    },
    [...idDependencies],
  );

  /** Upon the succesful submission of a PUT/POST ActivityInfo call, activate the touched activity and then navigate */
  const onSuccess_Activate = useCallback(
    async (newActivityId: number, activity: Activity) => {
      try {
        const request: PutActivityInfoRequest = {
          activityId: newActivityId,
          groupName,
          body: {
            operation: keys.Operation.activate,
            updatedBy: alias,
            comment,
          },
        };
        await PutActivityInfo(request);
      } catch (error) {
        // If activation fails, notify the user and then try to navigate to that activity
        dispatch(
          actions.page.addToFlashbar({
            id: ACTIVITY_FORM_FLASHBAR_ID,
            message: {
              type: 'error',
              header: `Failed to activate activity after ${action}`,
              content: error.message,
              dismissible: true,
            },
          }),
        );
      }
      onSuccess_Navigate(newActivityId, activity);
    },
    [...idDependencies, comment],
  );

  /**
   * A nested function which takes in an "onSuccess" callback function and return an async valud submission function to be passed to
   * react-hook-form's `form.handleSubmission` call (or `<ReactHookForm onValidSubmit />`)
   *
   * https://react-hook-form.com/docs/useform/handlesubmit
   *
   * @param {Function} onSuccess This function constructor takes an "onSuccess" function as its only argument. This
   * function will execute if the PUT/POST ActivityInfo API call succeeds. Use this function to follow up upon Successfully editing,
   * copying, or creating an activity, e.g. to navigate to that activity's page or to make some further API calls on that activity.
   *
   * @returns An asynchronous onValidSubmit function to be called by `form.handleSubmission`
   */
  const onValidSubmitWithSuccess = useCallback(
    (onSuccess: (activityId: number, activityForm: Activity) => Promise<void>) => async (activityForm: Activity) => {
      try {
        const newActivityId = await CallActivityApi(action, activityForm, alias, groupName, activityId);
        // If the API call succeeds, proceed to the chained "onSuccess" call
        onSuccess(newActivityId, activityForm);
      } catch (error) {
        // If the PUT/POST call fails, notify the user and log the error for easier debugging
        console.error(`Error while attempting to ${action} activity`, error);
        dispatch(
          actions.page.addToFlashbar({
            id: ACTIVITY_FORM_FLASHBAR_ID,
            message: {
              type: 'error',
              header: `Failed to ${action} activity ${activityId ?? ''}`,
              content: error.message,
              dismissible: true,
            },
          }),
        );

        // Special case of error, see Runbook; https://w.amazon.com/bin/view/Hammerstone/DATA_TOOLS_RUNBOOK/#HSaveActivityErrorwheneditingHammerstoneJob
        if (/failed to lazily initialize a collection of role/.test(error.message)) {
          dispatch(
            actions.page.addToFlashbar({
              id: 'activity_form_lazy_init_flashbar',
              message: {
                type: 'info',
                header: `Some steps that might fix this error:`,
                content: `1. Pause and resume this activity \n
                   2. Reschedule this activity for 1 minute later (or earlier) and then re-activate \n
                   3. Create a copy of this activity, then pause/delete the old version`,
                dismissible: true,
              },
            }),
          );
        }
      }
    },
    [...idDependencies],
  );

  const ActivateButton = (
    <>
      <Button
        data-testid={`${action}ActivitySaveAndActivate`}
        onClick={(e) => {
          e.preventDefault();
          setIsModalVisible(true);
        }}
        loading={form.formState.isSubmitting}
      >
        {ActivityFormActions[action]} and activate
      </Button>
      <ConfirmActionModal
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        confirmName={`${ActivityFormActions[action]} and activate`}
        onConfirm={() => {
          // Forces refresh of react-hook-form validation to ensure all fields remain valid before submission
          // https://react-hook-form.com/api/useform/trigger/
          form.trigger();
          form.handleSubmit(onValidSubmitWithSuccess(onSuccess_Activate))();
        }}
        header={`Are you sure you want to ${action} and activate activity ${activityId ?? ''}?`}
      >
        <RevisionCommentField comment={comment} setComment={setComment} />
      </ConfirmActionModal>
    </>
  );

  return {
    /** The current react-hook-form Activity form (https://react-hook-form.com/docs/useform)*/
    form,
    /** A callback function to execute upon cancelling a form (exits to the relevant page) */
    onCancel,
    /** An asynchronous function to pass into react-hook-form's `form.handleSubmission` (or `<ReactHookForm onValidSubmit />`) */
    onValidSubmit: onValidSubmitWithSuccess(onSuccess_Navigate),
    /** An Element which will render a "Save and activate" style button which open a modal for the user to confirm
     * and include a revision comment */
    ActivateButton,
    /** The currently selected groupName */
    groupName,
    /** The alias of the current user */
    alias,
    /** The revision comment state */
    comment,
    /** The revision comment state setter */
    setComment,
    /** The modal visibility state  */
    isModalVisible,
    /** The modal visibility state setter: use this to open the modal from a parent component */
    setIsModalVisible,
    /** The current search parameters from react-router: https://reactrouter.com/en/main/hooks/use-search-params */
    searchParams,
    /** The current search parameters setter for react-router: https://reactrouter.com/en/main/hooks/use-search-params */
    setSearchParams,
    /** The navigate hook to change pages within react-router without refreshing: https://reactrouter.com/en/main/hooks/use-navigate */
    navigate,
    /** Dispatch a redux reducer (action): https://react-redux.js.org/api/hooks#usedispatch  */
    dispatch,
  };
}

export default useActivityForm;
