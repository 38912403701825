import { CodeEditorProps, DateRangePickerProps, FlashbarProps } from '@amzn/awsui-components-react';
import store from '../data/redux';
import { HelpPanels } from 'src/components/helpers';

export type ReduxState = ReturnType<typeof store.getState>;
export type GetStateType = typeof store.getState;
export type DispatchType = typeof store.dispatch;

export interface DataObjectType {
  data: any;
  fetching: boolean;
  lastUpdated: Date;
}

export interface DataSliceType<T> {
  data: {
    [dataId: string]: T;
  };
  fetching: {
    [dataId: string]: boolean;
  };
  hasFetched: {
    [dataId: string]: boolean;
  };
  lastFetched: {
    [dataId: string]: string;
  };
}

export interface InvalidMessage {
  message: string | null;
  label: string;
  visible: boolean;
}

export interface FormSliceType {
  fields: { [key: string]: any };
  // invalidMessages maps keys (corresponding to flattened field names) to InvalidMessage objects.
  // A key can take on three possible states:
  //    1) Not yet validated: (message = null)
  //    2) Validated: (message = '')
  //    3) Invalid: (message = some string explaining why/how field is invalid)
  invalidMessages: { [key: string]: InvalidMessage };
  isValid: boolean;
  submitting: boolean;
  submitAttempted: boolean;
  errorMessage: { header: string; message: string };
}

export const DataTypes = {
  activity: 'Activity',
  pipeline: 'Pipeline',
  pipelineNameByGroup: 'Pipelines',
  instance: 'Instance',
  instanceByActivityId: 'Instances',
  activityInfoCache: 'Activity cache',
  clustersByGroup: 'Clusters',
  iamRolesByGroup: 'IAM roles',
  djsJobId: 'DJS activity ID',
  odinMaterialSets: 'Odin material sets',
} as const;

/**
 * The names of different data objects returned from the API and stored in Redux,
 * e.g. GetActivityInfo-->activity, GetPipelineInfo-->pipeline etc.
 */
export type DataType = keyof typeof DataTypes;

/**
 * Maps each DataType to its corresponding primary identifier
 */
export const DataTypePrimaryId: { [key in DataType]: string } = {
  activity: 'activityId',
  activityInfoCache: 'activityId',
  instanceByActivityId: 'activityId',
  djsJobId: 'activityId',
  instance: 'instanceId',
  clustersByGroup: 'groupName',
  iamRolesByGroup: 'groupName',
  pipelineNameByGroup: 'groupName',
  pipeline: 'pipelineName',
  odinMaterialSets: 'groupName',
};

export function blankDataSlice<T>() {
  return {
    data: {},
    fetching: {},
    hasFetched: {},
    lastFetched: {},
  } as DataSliceType<T>;
}

// USER SLICE TYPES:

// TODO: Implement serialization to store components & functions in the redux store
export interface SerializedFlashbarMessage extends FlashbarProps.MessageDefinition {
  header: string;
  content?: string;
  // NOTE: Flashbar actions are not currently supported
  action?: never;
}

export interface ReduxFlashbarItem {
  id: string;
  persistAcrossApp?: boolean;
  message: SerializedFlashbarMessage;
}

export interface announcementBannersPreferences {
  dismissOldConsole: boolean;
  dismissEditActivityRisk: boolean;
  dismissAdblockAlert: boolean;
}

export interface MonitorActivityPreferences {
  dateRange: DateRangePickerProps.Value;
  instanceStatusId: number;
}

export interface DatetimePreferences {
  isAbsolute: boolean;
}
export interface UserSliceType {
  auth: {
    alias: string;
    groupName: string;
    ldapGroups: string[];
    hammerstoneGroups?: string[];
  };
  preferences: {
    codeEditor: CodeEditorProps['preferences'];
    datetime: DatetimePreferences;
    announcementBanners: announcementBannersPreferences;
  };
}

export interface PageSliceType {
  oldHammerstoneHref: string;
  groupSelectorDisabled: boolean;
  flashbarItems: { [id: string]: ReduxFlashbarItem };
  helpPanel: keyof typeof HelpPanels;
  toolsOpen: boolean;
  formFieldErrors: { [path: string]: string };
  pipelineCooldown: { [pipelineName: string]: boolean };
}
