import React from 'react';
import { FormField, Input } from '@amzn/awsui-components-react';

export default function RevisionCommentField(props: { comment: string; setComment: Function }) {
  return (
    <FormField label="Revision comment" data-testid="activate-revision-comment">
      <Input
        value={props.comment}
        onChange={({ detail }) => props.setComment(detail.value)}
        placeholder="Revision comment (optional)"
      />
    </FormField>
  );
}
