import React, { useState } from 'react';
import store, { actions, useActivity, useAlias, useGroupName } from '../../data/redux';
import { Button } from '@amzn/awsui-components-react';
import { fetchActivityInfo } from '../../data/api/fetchFromAPI';
import keys from '../../constants/hammerstoneConstantKeys';
import { PutActivityInfo } from 'src/lib/hammerstoneApi';
import { ConfirmActionModal, CustomSVG, tempFlashbarMessage } from '../helpers';
import RevisionCommentField from '../helpers/RevisionCommentField';

const { EDIT_PENDING, NEW, SCHEDULED, PAUSED, COMPLETED } = keys.StatusCode;
const { activate, pause, resume } = keys.ActivityActions;

export default function ActivityToggleStatusButton({ activityId }: { activityId: string }) {
  const { data: activity, fetching } = useActivity(activityId);

  const status = activity?.activityStatusCode;
  const alias = useAlias();
  const groupName = useGroupName();

  // Revision comment for modal
  const [comment, setComment] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  // State which tracks whether the button is waiting for an API response
  const [isCalling, setIsCalling] = useState(false);

  const disabled = !activity || !activityId || !alias || !groupName;
  const loading = fetching || isCalling;
  const buttonProps = { loading, disabled, 'data-testid': 'toggleActivityStatus' };

  function setActivityStatus(operation: keyof typeof keys.Operation) {
    setIsCalling(true);
    PutActivityInfo({
      groupName,
      activityId: parseInt(activityId),
      body: { operation, updatedBy: alias, comment: operation === activate ? comment : undefined },
    })
      .then(() => {
        fetchActivityInfo({ groupName, activityId: parseInt(activityId) }, true);
        tempFlashbarMessage({
          id: `update_activity_status_success`,
          message: { header: `Successfully ${operation}d activity ${activity?.activityNamespace} (${activityId})` },
        });
      })
      .catch((error) => {
        console.error(`Failed to ${operation} activity`, error);
        store.dispatch(
          actions.page.addToFlashbar({
            id: `${operation}_activity_failure`,
            message: {
              type: 'error',
              header: `Failed to ${operation} activity ${activityId}`,
              content: error.message,
              dismissible: true,
            },
          }),
        );
      })
      .finally(() => {
        setIsCalling(false);
      });
  }

  if ([EDIT_PENDING, NEW, COMPLETED].includes(status as any)) {
    return (
      <>
        <Button {...buttonProps} onClick={() => setIsModalVisible(true)} iconSvg={CustomSVG.Play}>
          Activate activity
        </Button>
        <ConfirmActionModal
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          confirmName="Activate"
          onConfirm={() => setActivityStatus(activate)}
          header={`Activate activity ${activityId} ?`}
        >
          <RevisionCommentField comment={comment} setComment={setComment} />
        </ConfirmActionModal>
      </>
    );
  } else if (status === PAUSED) {
    return (
      <Button {...buttonProps} onClick={() => setActivityStatus(resume)} iconSvg={CustomSVG.Resume}>
        Resume activity
      </Button>
    );
  } else if (status === SCHEDULED) {
    return (
      <Button {...buttonProps} onClick={() => setActivityStatus(pause)} iconSvg={CustomSVG.Pause}>
        Pause activity
      </Button>
    );
  } else {
    // Show no button if activity status is not expected
    return <></>;
  }
}
