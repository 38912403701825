import React from 'react';
import { DateRangePicker, DateRangePickerProps } from '@amzn/awsui-components-react';
import { dateRangePicker_i18n } from '../../constants/i18n';
import { convertTime, msBetweenDates, dateToApiString, TimeUnits } from 'src/commons';
import { useSearchParams } from 'react-router-dom';

const relativeOptions: DateRangePickerProps['relativeOptions'] = [
  { amount: 1, unit: 'day', type: 'relative', key: 'prev-1-day' },
  { amount: 1, unit: 'week', type: 'relative', key: 'prev-1-week' },
  { amount: 1, unit: 'month', type: 'relative', key: 'prev-1-month' },
  { amount: 1, unit: 'year', type: 'relative', key: 'prev-1-year' },
];

export function dateRangeToStartAndEndDatetimes(value: DateRangePickerProps.Value): [string, string] {
  if (!value) {
    return [undefined, undefined];
  } else if (value.type === 'relative') {
    const now = new Date().getTime();
    return [dateToApiString(now - convertTime(value.amount, value.unit, 'millisecond')), dateToApiString(now)];
  } else {
    return [dateToApiString(value.startDate), dateToApiString(value.endDate)];
  }
}

const isValidRange: DateRangePickerProps['isValidRange'] = (range) => {
  if (range.type === 'absolute') {
    const [startDate, startTime] = range.startDate.split('T');
    const [endDate, endTime] = range.endDate.split('T');
    if (!startDate || !endDate) {
      return {
        valid: false,
        errorMessage: 'The selected date range is incomplete: please select a start and end date.',
      };
    }
    if (!startTime || !endTime) {
      return {
        valid: false,
        errorMessage: 'The selected date range is incomplete: please include a start and end time.',
      };
    }
    if (msBetweenDates(range.startDate, range.endDate) <= 0) {
      return {
        valid: false,
        errorMessage: 'The selected date range is invalid: please select an end date that is AFTER the start date.',
      };
    }
  }
  if (range.type === 'relative') {
    if (!TimeUnits.includes(range.unit)) {
      return {
        valid: false,
        errorMessage: 'The selected unit of time is invalid: please select a unit from the provided dropdown.',
      };
    }
    if (range.amount <= 0) {
      return {
        valid: false,
        errorMessage: 'The selected duration is invalid: please input a positive, non-zero amount.',
      };
    }
  }

  return { valid: true };
};

/**
 * For more information on the Polaris `<DateRangePicker>` component see documentation: https://polaris.a2z.com/components/date-range-picker/
 * This component manages the values of {startDateTime, endDateTime} in Redux user slice at state.users.preferences.monitorActivity
 */
export function InstanceDateRangePicker() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <DateRangePicker
      onChange={({ detail }) => {
        setSearchParams((params) => {
          params.set('dateRange', JSON.stringify(detail.value));
          return params;
        });
      }}
      value={JSON.parse(searchParams.get('dateRange'))}
      i18nStrings={{ ...dateRangePicker_i18n, applyButtonLabel: 'Fetch instances' }}
      placeholder="Pick a datetime range (UTC)"
      relativeOptions={relativeOptions}
      isValidRange={isValidRange}
      data-testid="monitorActivityInstanceDateRangePicker"
    />
  );
}

export default InstanceDateRangePicker;
