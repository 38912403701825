import { Badge, Button, Link, SelectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Column, ColumnContainer, LabeledContent, LabeledContentSkeleton, transformTo } from '../../helpers';
import { ActivityType } from '../../../constants/hammerstoneConstants';
import PAGES from '../../../nav/Pages';
import { useConditionalEffect, objToOptions } from 'src/commons/';
import Activity, { ActivityContainerProps } from '../../../interfaces/activityInterfaces';

import { fetchInstanceById, fetchPipelineInfo, fetchPipelineNames } from '../../../data/api/fetchFromAPI';
import { urlSearchString, useInternalOnFollow } from 'src/nav/navHelper';
import store, { s3CredentialTypeSelector, useFormField, useGroupName, usePipeline, usePipelines } from 'src/data/redux';
import DjsActivityContent from './DjsActivityContent';
import { getOldHammerstoneEndpoint } from 'src/constants/config';
import Content, { Rules } from 'src/components/helpers/content/';
import { DisplayMode } from 'src/components/helpers/content/contentInterfaces';
import { LastExecutionDate, LastExecutionStatus, useActivityWithStatus } from '../LastActivityExecution';

interface iAboutContainer extends ActivityContainerProps {
  activityTypeOptions?: SelectProps.Options;
}

function ViewAboutContainer(props: iAboutContainer) {
  const groupName = useGroupName();
  const onFollow = useInternalOnFollow();
  const activityWithStatus = useActivityWithStatus(props.activityId);

  const contentProps = {
    resourceId: props.activityId,
    resourceType: 'activity',
    mode: DisplayMode.View,
  } as const;

  return (
    <ColumnContainer header={props.header} columns={3}>
      <Column>
        <Content.Text
          {...contentProps}
          label="Activity status"
          path="activityStatusCode"
          data-testid="activity-status"
          viewTransform={transformTo.statusIndicator}
        />
        <Content.Select
          {...contentProps}
          label="Type"
          path="activityType"
          viewTransform={(type) => <Badge color="blue">{type}</Badge>}
          options={props.activityTypeOptions}
          infoHelpPanel="ActivityType"
        />
        <Content.Number
          {...contentProps}
          label="Revisions"
          path="revision"
          // TODO: Eventually link revision to ActivityHistory page once implemented in new UI & API. Note that HS API currently returns INCORRECT revision
          viewTransform={(revision) => (
            <SpaceBetween size="s" direction="horizontal">
              <Badge color="green">{revision}</Badge>
              <Link
                // Links directly back to old UI experience (should open in new tab)
                external
                target="_blank"
                href={
                  getOldHammerstoneEndpoint() +
                  PAGES.ACTIVITY_HISTORY.pageLayoutProps({ activityId: props.activityId }).oldHref
                }
              >
                History
              </Link>
            </SpaceBetween>
          )}
          missingText="activate activity to create revision"
        />
        <Content.Textarea {...contentProps} label="Revision comment" path="comment" missingText="no revision comment" />
        <DjsActivityContent activityId={contentProps.resourceId} mode={contentProps.mode} />
      </Column>
      <Column>
        <Content.Text
          {...contentProps}
          label="Pipeline"
          path="activityNamespace"
          viewTransform={(pipelineName: any) => (
            <Link
              onFollow={onFollow}
              href={PAGES.VIEW_PIPELINE.path.replace(':pipelineName', pipelineName) + urlSearchString({ groupName })}
            >
              {pipelineName}
            </Link>
          )}
        />
        <Content.Text
          {...contentProps}
          label="Last execution instance ID"
          path="activityInstanceId"
          missingText="no activity instance ID found"
          viewTransform={(instanceId) =>
            instanceId ? (
              <Link
                href={
                  PAGES.VIEW_INSTANCE.path.replace(':instanceId', instanceId).replace(':activityId', props.activityId) +
                  urlSearchString({ groupName })
                }
                onFollow={onFollow}
              >
                {instanceId}
              </Link>
            ) : null
          }
        />
        <LabeledContent label="Last execution status" missingText={null} data-testid="last-execution-status">
          <LastExecutionStatus lastExecutionStatus={activityWithStatus?.lastExecutionStatus} />{' '}
          <Button
            variant="inline-icon"
            iconName="refresh"
            onClick={() =>
              fetchInstanceById(
                {
                  groupName,
                  activityId: parseInt(props.activityId),
                  instanceId: activityWithStatus?.activityInstanceId,
                },
                true,
              )
            }
          />
        </LabeledContent>
        <LabeledContent label="Last execution date" missingText={null}>
          <LastExecutionDate
            lastExecutionDate={activityWithStatus?.lastExecutionDate}
            activityStatusCode={activityWithStatus?.activityStatusCode}
          />
        </LabeledContent>
        <Content.Text
          {...contentProps}
          label="Confidence file"
          path="confidenceFileLocation"
          infoHelpPanel="ConfidenceFile"
        />
      </Column>
      <Column>
        <Content.Datetime {...contentProps} label="Create date" path="createDate" />
        <Content.Text {...contentProps} label="Created by" path="createdBy" viewTransform={transformTo.userProfile} />
        <Content.Datetime {...contentProps} label="Update date" path="updateDate" />
        <Content.Text {...contentProps} label="Updated by" path="updatedBy" viewTransform={transformTo.userProfile} />
      </Column>
    </ColumnContainer>
  );
}

function EditAboutContainer(props: iAboutContainer) {
  const groupName = useGroupName();

  // Will only be edited during Activity creation, otherwise is view-only
  const { data } = store.getState();
  const contentProps = { mode: DisplayMode.Edit, resourceType: 'activity', resourceId: '' } as const;
  const { data: pipelines } = usePipelines(groupName);
  const pipelineName: string = useFormField<Activity>('activityNamespace');
  const { data: pipeline } = usePipeline({ groupName, pipelineName });
  const activityNames = pipeline
    ? pipeline.activityInfoList
        .filter(({ activityId }) => activityId.toString() !== props.activityId)
        .map(({ activityName }) => activityName)
    : [];

  useConditionalEffect(() => {
    fetchPipelineNames({ groupName });
  }, [groupName]);

  useConditionalEffect(() => {
    fetchPipelineInfo({ groupName, pipelineName });
  }, [pipelineName, groupName]);

  const [pipelineOptions, setPipelineOptions] = useState<SelectProps.Option[]>(undefined);

  useEffect(() => {
    // This Effect hook updates which pipeline options are disabled due to their usage of Odin credentials
    if (pipelines?.length) {
      setPipelineOptions(
        pipelines.map((p) => {
          return {
            value: p,
            label: p,
            disabled:
              data.pipeline.data[p]?.s3CredentialType === 'ODIN' ||
              (p === pipelineName && pipeline?.s3CredentialType === 'ODIN'),
          };
        }),
      );
    }
  }, [pipelineName, pipeline, pipelines]);

  return (
    <ColumnContainer header={props.header} columns={3}>
      <Content.Text
        {...contentProps}
        label="Activity name"
        path="activityName"
        rules={(l) => ({
          required: Rules.required(l),
          minLength: Rules.minLength(1, l),
          maxLength: Rules.maxLength(128, l),
          pattern: Rules.pattern.lettersNumbersHyphenUnderscore(l),
          validate: {
            uniqueName: (activityName) =>
              !activityNames.includes(activityName) ||
              `An activity named "${activityName}" already exists in pipeline ${pipelineName}, please choose a unique name.`,
          },
        })}
        placeholder="New activity name..."
      />
      <Content.Select
        {...contentProps}
        path="activityNamespace"
        label="Pipeline"
        options={pipelineOptions}
        filteringType="auto"
        rules={(l) => ({
          required: Rules.required(l),
          validate: {
            iamPipeline: (pipelineName) =>
              // Pulls the current version of the Redux state to determine whether this activity belongs to a deprecated Odin pipeline
              s3CredentialTypeSelector(pipelineName)(store.getState()) !== 'ODIN' ||
              `Pipeline "${pipelineName}" uses Odin credentials. Since Odins are being deprecated, activity creation is forbidden in Odin pipelines. Please select another pipeline or migrate this pipeline to IAM.`,
          },
        })}
      />
      <Content.Select
        {...contentProps}
        path="activityType"
        label="Activity type"
        options={props.activityTypeOptions}
        infoHelpPanel="ActivityType"
        rules={(l) => ({ required: Rules.required(l) })}
      />
    </ColumnContainer>
  );
}

/** A container with overview information about a particular Activity */
export default function AboutContainer(props: iAboutContainer) {
  // Memoized at top level to avoid unnecessary computations
  const ActivityTypeOptions = useMemo(() => objToOptions(ActivityType), []);

  switch (props.mode) {
    case DisplayMode.View:
      return <ViewAboutContainer {...props} activityTypeOptions={ActivityTypeOptions} />;
    case DisplayMode.Edit:
      if (props.activityId) {
        // No "about" container for editing an activity, only creating
        return null;
      } else {
        return <EditAboutContainer {...props} activityTypeOptions={ActivityTypeOptions} />;
      }
    case DisplayMode.Loading:
      return (
        <ColumnContainer header={props.header} columns={3}>
          <LabeledContentSkeleton label="Name" />
          <LabeledContentSkeleton label="Pipeline" />
          <LabeledContentSkeleton label="Type" />
        </ColumnContainer>
      );
  }
}
