import { GetGroupsUsingLdap, GetUserLdapGroups } from 'src/lib/hammerstoneApi';
import {
  MidwayIdentityCredentialProviderSTS,
  MidwayIdentityCredentialSTS,
  MidwayNetworkPartition,
} from '@amzn/midway-identity-credential-provider';
import {
  AWS_CREDS_NUM_RETRIES,
  HAMMERSTONE_ADMIN_GROUP,
  getHsApiClientIamRoleArn,
  getHSApiConfig,
  getServiceRegion,
} from '../constants/config';
import store, { actions } from '../data/redux';
import { tempFlashbarMessage } from 'src/components/helpers';

let hsApiCredentialProvider: MidwayIdentityCredentialProviderSTS;
let repoCredentialProvider: MidwayIdentityCredentialProviderSTS;
let hsApiAwsCredentials: MidwayIdentityCredentialSTS;
let repoAwsCredentials: MidwayIdentityCredentialSTS;

/**
 * Redirects to Midway web portal and asks user to authenticate.
 * Then it retrieves Sigv4 credentials from the IAM role provided.
 * Midway library auto-refreshes both token and creds if needed.
 *
 * @returns Object that contains AWS credentials and Midway user that is used to retrieve those credentials
 * {
 *   AccessKeyId: AWS Sigv4 Access Key
 *   SecretAcessKey: AWS Sigv4 Secret Key
 *   SessionToken: String that indicates a unique session for the Sigv4 key pair
 *   Expiration: Date that tell when the temporary keys expire
 *   MidwayUser: Midway user alias that is decoded from the JWT vended by Midway service
 * }
 */
export const fetchTemporaryAwsCredentials = async (iamRoleArn: string): Promise<MidwayIdentityCredentialSTS> => {
  // Each API service client should have a different client instantiated with a different role to eliminate impersonation
  if (iamRoleArn.endsWith('HammerstoneAPITempCreds')) {
    if (!hsApiCredentialProvider) {
      hsApiCredentialProvider = new MidwayIdentityCredentialProviderSTS(iamRoleArn, MidwayNetworkPartition.AWS, {
        maxAttempts: AWS_CREDS_NUM_RETRIES,

        // STSClient config can set specific parameters, such as region (for air-gapped partitions)
        // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-sts/interfaces/stsclientconfig.html
        //
        // By default, the AWS Security Token Service (AWS STS) is available as a global service,
        // and all AWS STS requests go to a single endpoint at https://sts.amazonaws.com.
        // AWS recommends using Regional AWS STS endpoints instead of the global endpoint to reduce latency,
        // build in redundancy, and increase session token validity.
        // https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_temp_enable-regions.html
        region: getServiceRegion(),
      });
    }

    hsApiAwsCredentials = await hsApiCredentialProvider.getCredentials();
    return hsApiAwsCredentials;
  } else if (iamRoleArn.endsWith('HammerstoneRepoTempCredsRole')) {
    if (!repoCredentialProvider) {
      // If the IAM role used ends with 'HammerstoneRepoTempCredsRole' for Repository Service IAM role
      repoCredentialProvider = new MidwayIdentityCredentialProviderSTS(iamRoleArn, MidwayNetworkPartition.AWS, {
        maxAttempts: AWS_CREDS_NUM_RETRIES,
        region: getServiceRegion(),
      });
    }

    repoAwsCredentials = await repoCredentialProvider.getCredentials();
    return repoAwsCredentials;
  }
};

/**
 * Get user alias. Request Midway token if not available, will redirect to SSO if necessary.
 *
 * @returns {Promise<string>} Midway user alias
 */
export const getMidwayUser = async (): Promise<string> => {
  if (!hsApiAwsCredentials) {
    // default to using HS Api's IAM role
    const config = getHSApiConfig();

    // get/refresh the temporary Midway credentials object, which also has Midway user alias
    const iamRoleArn = getHsApiClientIamRoleArn(config.iamRolePartition, config.iamRoleAccount);
    hsApiAwsCredentials = await fetchTemporaryAwsCredentials(iamRoleArn);
  }

  return hsApiAwsCredentials.MidwayUser;
};

/**
 * Save user alias, LDAP groups, and Hammerstone groups to redux store.
 *
 * @param {string} searchParamsGroupName The Hammerstone groupName defined in the search params (e.g. path/?groupName=searchParamsGroupName). If this is a valid Hammerstone group, it will be selected.
 */
export const initializeUserGroups = async () => {
  try {
    const alias = await getMidwayUser();
    store.dispatch(actions.user.setAlias(alias));

    const { ldapGroups } = await GetUserLdapGroups({ alias });
    store.dispatch(actions.user.setLdapGroups(ldapGroups));

    const { hammerstoneGroups } = await GetGroupsUsingLdap({ body: { ldapGroups } });
    store.dispatch(actions.user.setHammerstoneGroups(hammerstoneGroups));
  } catch (e) {
    // TODO: Log error to CW RUM.
    store.dispatch(actions.user.setHammerstoneGroups([]));
  }
};

/**
 * Sets the hammerstone groupName (in Redux state.user) using a list of hammerstone groups and (optional) preferred group
 */
export function setHammerstoneGroupName(hammerstoneGroups: string[], preferredGroup?: string) {
  const groupName = getDefaultGroupName(hammerstoneGroups, preferredGroup);
  store.dispatch(actions.user.setGroupName(groupName));
}

/**
 * Returns the default/initial groupName from a given list of hammerstoneGroups and an optional preferred group (e.g. the user-defined groupName in searchParams)
 */
function getDefaultGroupName(hammerstoneGroups: string[], preferredGroup?: string): string {
  // If there're no groups, return null. Customer will be redirected to home screen.
  if (!hammerstoneGroups?.length) {
    return null;
  }
  if (preferredGroup) {
    // If the user has access to their preferred group, use it
    if (hammerstoneGroups.includes(preferredGroup)) {
      return preferredGroup;
    }
    // Otherwise warn the user that their preferred group was invalid
    tempFlashbarMessage(
      {
        id: 'invalid-search-groupName',
        persistAcrossApp: true,
        message: {
          type: 'warning',
          header: `Cannot set Hammerstone group to "${preferredGroup}":`,
          content: 'The user either is not a member, or the group does not exist. Defaulting to a valid groupName.',
          dismissible: true,
        },
      },
      5 * 1000,
    );
  }
  // If the user is a member of the admin group (AWS-DW-PRIMARY), return that
  if (hammerstoneGroups.includes(HAMMERSTONE_ADMIN_GROUP)) {
    return HAMMERSTONE_ADMIN_GROUP;
  }
  // Otherwise default to the first group in the list
  return hammerstoneGroups[0];
}
