import React, { useEffect } from 'react';
//TODO: Add dark mode https://polaris.a2z.com/get-started/dev-guides/dark-mode-implementation/
import '@amzn/awsui-global-styles/polaris.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import 'prismjs/themes/prism.css';
import { initializeUserGroups, setHammerstoneGroupName } from '../lib/auth';
import { useSearchParams } from 'react-router-dom';
import { useGroupName, useHammerstoneGroups } from 'src/data/redux';
import { PageRouter } from 'src/nav/Pages';
import { AwsRum } from 'aws-rum-web';
import { getAwsRumConfig, getServiceDomain, getServiceRegion } from 'src/constants/config';
import { clearOldRecentPagesCache } from 'src/commons';

const setupAWSRUM = () => {
  try {
    const config = getAwsRumConfig();

    const awsRum: AwsRum = new AwsRum(
      config.applicationId,
      config.applicationVersion,
      config.applicationRegion,
      config.awsRumConfig,
    );
    return awsRum;
  } catch (error) {
    console.log(error)
    // Ignore errors thrown during CloudWatch RUM web client initialization
    return undefined;
  }
};

export default function App() {
  // Executes all hooks at the top-level of the component (not nested) to avoid errors
  const [searchParams, setSearchParams] = useSearchParams();
  const preferredGroup = searchParams.get('groupName');
  const groupName = useGroupName();
  const hammerstoneGroups = useHammerstoneGroups();

  useEffect(() => {
    // Execute the following initializations when the App first mounts
    clearOldRecentPagesCache();
    if (getServiceDomain() !== 'devDesktop' && getServiceRegion() === 'us-east-1') {
      setupAWSRUM();
    }
    initializeUserGroups();
  }, []);

  useEffect(() => {
    // Initializes hammerstone groupName upon changes to search params or hammerstone groups
    if (hammerstoneGroups?.length) {
      setHammerstoneGroupName(hammerstoneGroups, preferredGroup);
    }
  }, [JSON.stringify(hammerstoneGroups || []), preferredGroup]);

  useEffect(() => {
    // Executes when the hammerstone groupName changes
    // Sets the groupName search param FROM the Redux state groupName (source of truth)
    if (groupName && preferredGroup !== groupName) {
      setSearchParams((s) => {
        s.set('groupName', groupName);
        // Removes unnecessary & distracting token from URL bar (Midway)
        s.delete('id_token');
        return s;
      });
    }
  }, [groupName]);

  return <PageRouter />;
}
