import { useNavigate } from 'react-router';
import { useGroupName, useHammerstoneGroups } from 'src/data/redux';
import { setHammerstoneGroupName } from 'src/lib/auth';

/**
 * A hook which returns an onFollow function for links and buttons, uses `useNavigate`. Allows for instant navigation without refresh for internal pages.
 */
export function useInternalOnFollow() {
  const navigate = useNavigate();
  const hammerstoneGroups = useHammerstoneGroups();
  const groupName = useGroupName();

  return function (event: CustomEvent) {
    if (event.detail.external) {
      //For external traffic, allow default behavior (open new tab with given href)
      return;
    } else {
      //For internal traffic, use `navigate` for no-refresh routing
      event.preventDefault();
      // Parses out the search praameters from the given href
      const [pathname, search] = event.detail.href.split('?');

      const searchParams = new URLSearchParams(search);
      const preferredGroup = searchParams.get('groupName');
      if (hammerstoneGroups?.length && preferredGroup && preferredGroup !== groupName) {
        setHammerstoneGroupName(hammerstoneGroups, preferredGroup);
      }

      // Navigates to the given href destination with the included searchParams
      navigate({ pathname, search });
    }
  };
}

/**
 * Example:
 *
 *      const x = {a : 1 , b: 2};
 *      const z = {name: undefined};
 *      searchString(x); // return '?a=1&b=2'
 *      searchString(z); // return ''
 *
 * @param {object} obj String key-value pairs to convert to URL Search parameters
 * @returns {string} A ?-prefixed URL search string, if any of the values are defined, otherwise an empty string.
 */
export const urlSearchString = (obj: { [key: string]: string }) => {
  //Filters out falsy (undefined/empty/null) parameters
  const filteredParams = Object.entries(obj).filter(([, value]) => value);
  const params = Object.fromEntries(filteredParams);
  return '?' + new URLSearchParams(params).toString();
};

/** From https://stackoverflow.com/a/11384018  */
export function openInNewTab(url: string) {
  var win = window.open(url, '_blank');
  win.focus();
}
