export const Operation = {
  update: 'Update',
  pause: 'Pause',
  resume: 'Resume',
  activate: 'Activate',
  rerun: 'Rerun',
} as const;

export const PipelineActions = {
  ...Operation,
  delete: 'Delete',
} as const;

export const ActivityActions = {
  ...Operation,
  copy: 'Copy',
  delete: 'Delete', // delete is a reserved word
  create: 'Create',
} as const;

export const ActivityFormActions = {
  edit: 'Edit',
  copy: 'Copy',
  create: 'Create',
} as const;

export const ClearanceLevel = {
  OWNER: 'Owner',
  EDIT: 'Edit',
  READ: 'Read',
  SUBSIDIARY_GROUP: 'Subsidiary group',
  CROSSGROUP_SHARED: 'Cross-group shared',
  ACCESS_DENIED: 'Access denied',
} as const;

export const ActivityType = {
  EXTRACT: 'Extract',
  TRANSFORM: 'Transform',
  COPY: 'Load',
} as const;

export const StatusIdToCode = {
  1: 'CREATED',
  2: 'DELETED',
  3: 'RUNNING',
  4: 'ENQUEUED',
  5: 'FAILURE',
  6: 'SUCCESS',
  7: 'NEW',
  8: 'EDIT_PENDING',
  9: 'DELETE_PENDING',
  10: 'ACTIVATE_PENDING',
  11: 'SCHEDULED',
  12: 'DISCOVERED',
  13: 'PROCESSED',
  14: 'PAUSED',
  15: 'DEACTIVATED',
  16: 'SUBMITTED',
  17: 'ENABLED',
  18: 'DISABLED',
  19: 'READONLY',
  20: 'MIGRATION_ERROR',
  21: 'COMPLETED',
  22: 'ONHOLD',
  23: 'LOCKED',
  24: 'UNLOCKED',
  25: 'REJECTED',
  26: 'CANCELLED',
} as const;

export const StatusCode: { [key in (typeof StatusIdToCode)[keyof typeof StatusIdToCode]]: string } = {
  CREATED: 'Created',
  DELETED: 'Deleted',
  RUNNING: 'Running',
  ENQUEUED: 'Enqueued',
  FAILURE: 'Failure',
  SUCCESS: 'Success',
  NEW: 'New',
  EDIT_PENDING: 'Edit pending',
  DELETE_PENDING: 'Delete pending',
  ACTIVATE_PENDING: 'Activate pending',
  SCHEDULED: 'Scheduled',
  DISCOVERED: 'Discovered',
  PROCESSED: 'Processed',
  PAUSED: 'Paused',
  DEACTIVATED: 'Deactivated',
  SUBMITTED: 'Submitted',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  READONLY: 'Read only',
  MIGRATION_ERROR: 'Migration error',
  COMPLETED: 'Completed',
  ONHOLD: 'On hold',
  LOCKED: 'Locked',
  UNLOCKED: 'Unlocked',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
} as const;

//From: https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/Documentations/APIs#HGetActivityInfoResponseModel
export const DjsExecutionStatus = {
  ...StatusCode,
  WAITING_DEPENDENCIES: 'Waiting for preconditions',
  WaitingToBePolled: 'Waiting to be polled',
  ErrorCancellation: 'Error: Cancellation',
  DependencyTimeout: 'Dependency timeout',
  S3ConfigurationException: 'S3 configuration exception',
  ConfigurationException: 'Configuration exception',
  AgentPolledExecution: 'Agent polled execution',
  null: 'null',
} as const;

//Available Hammerstone regions, according to https://w.amazon.com/bin/view/HammerstoneAPIUserGuideTemp/Documentations/APIs#HGetActivityInfoResponseModel
export const Region = {
  'us-east-1': 'US East (N. Virginia)',
  'us-east-2': 'US East (Ohio)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'us-iso-east-1': 'US ISO East',
  'us-isob-east-1': 'US ISOB East (Ohio)',
  'us-gov-east-1': 'AWS GovCloud (US-East)',
  'us-gov-west-1': 'AWS GovCloud (US-West)',
  'eu-west-1': 'Europe (Ireland)',
  'eu-west-2': 'Europe (London)',
  'eu-west-3': 'Europe (Paris)',
  'eu-north-1': 'Europe (Stockholm)',
  'eu-central-1': 'Europe (Frankfurt)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-northeast-3': 'Asia Pacific (Osaka)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'cn-north-1': 'China (Beijing)',
  'cn-northwest-1': 'China (Ningxia)',
  'ca-central-1': 'Canada (Central)',
  'sa-east-1': 'South America (Sao Paulo)',
} as const;

export const Severity = {
  2: '2 - Bus. Crit. Function Impaired',
  6: '2.5 - Daytime Sev 2',
  3: '3 - Group Productivity Impaired',
  4: '4 - Individual Productivity Impaired',
  5: '5 - Productivity Not Immediately Affected',
} as const;

export const ActivityPriority = {
  1: '1 - Critical',
  2: '2 - High',
  3: '3 - Normal',
  4: '4 - Low',
} as const;

export const NotifyOnStatus = {
  SUCCESS: 'Success',
  RETRY: 'Retry',
  FAIL: 'Failure',
} as const;

export const SNSMessageOptions = {
  'group': 'Show Group',
  'pipeline': 'Pipeline Info',
  'activity type': 'Activity Type',
  'activity name': 'Activity Name',
  'activity id': 'Activity ID',
  'status': 'Execution Status',
  'instance id': 'Instance ID',
  'date': 'Scheduled Date',
  'updated by': 'Updated By',
  'cluster': 'Cluster',
  'priority': 'Priority',
  'retry active': 'Auto Retry',
  'custom message': 'Custom Message',
} as const;

export const SNSMessageOptionGroups = {
  'Default information': ['group', 'pipeline', 'activity type', 'activity name', 'activity id', 'status'],
  'Additional information': ['instance id', 'date', 'updated by', 'cluster', 'priority', 'retry active'],
  'Custom': ['custom message'],
};

export const ScheduleType = {
  HOURS: 'Hour(s)',
  DAYS: 'Day(s)',
  WEEKS: 'Week(s)',
  MONTHS: 'Month(s)',
  ONETIME: 'Run once',
} as const;

export const DependencyWait = [
  '5 mins',
  '10 mins',
  '15 mins',
  '20 mins',
  '25 mins',
  '30 mins',
  '35 mins',
  '40 mins',
  '45 mins',
  '50 mins',
  '55 mins',
  '60 mins',
  '1 hr 10 mins',
  '1 hr 20 mins',
  '1 hr 30 mins',
  '1 hr 40 mins',
  '1 hr 50 mins',
  '2 hrs',
  '2 hrs 15 mins',
  '2 hrs 30 mins',
  '2 hrs 45 mins',
  '3 hrs',
  '4 hrs',
  '5 hrs',
  '6 hrs',
  '7 hrs',
  '8 hrs',
  '9 hrs',
  '10 hrs',
  '11 hrs',
  '12 hrs',
  '13 hrs',
  '14 hrs',
  '15 hrs',
  '16 hrs',
  '17 hrs',
  '18 hrs',
  '19 hrs',
  '20 hrs',
  '21 hrs',
  '22 hrs',
  '23 hrs',
  '1 day',
  '1 day 2 hrs',
  '1 day 4 hrs',
  '1 day 6 hrs',
  '1 day 8 hrs',
  '1 day 10 hrs',
  '1 day 12 hrs',
  '1 day 14 hrs',
  '1 day 16 hrs',
  '1 day 18 hrs',
  '1 day 20 hrs',
  '1 day 22 hrs',
  '2 days',
  '2 days 12 hrs',
  '3 days',
] as const;

export const CompareOperator = {
  'GE': 'Greater or Equal (≥)',
  'LE': 'Less or Equal (≤)',
  'EQ': 'Equals (=)',
  'NOT EQ': 'Not Equals (≠)',
  'BETWEEN': 'Between (><)',
  'NOT BETWEEN': 'Not Between (<>)',
} as const;

export const PreconditionS3Type = {
  s3Key: 'S3 Key',
  s3Prefix: 'S3 Prefix',
} as const;

export const TransformParamType = {
  SQL: 'SQL',
  LIST: 'List',
  RANGE: 'Range',
  FREE_FORM: 'Free Form',
} as const;

export const TransformSQLType = {
  INSERT: 'Insert',
  UPDATE: 'Update',
} as const;

export const LoadReplaceOption = {
  INSERT: 'Insert',
  UPSERT: 'Upsert',
} as const;

export const LoadSourceType = {
  FILE: 'File',
  MANIFEST: 'Manifest',
} as const;

export const TargetDBType = {
  REDSHIFT: 'Redshift',
} as const;

export const LoadFileFormat = {
  DELIMITER: 'Delimiter',
  CSV: 'CSV',
  JSON: 'JSON',
  PARQUET: 'Parquet',
  CUSTOM: 'Custom',
} as const;

//NOTE: Delimiters is NOT exhaustive
export const Delimiters = {
  ',': 'comma',
  '\t': 'tab',
  '\n': 'line-break',
  '|': 'pipe',
  ':': 'colon',
  ';': 'semi-colon',
  ' ': 'space',
  '~': 'tilda',
  '': 'empty string',
} as const;

/**
 * S3 Credential types are an enum return from Hammerstone API. The corresopnding declarations can be found in...
 * Lambda: https://code.amazon.com/packages/AWSHammerstoneExposedRESTfulServiceLambda/blobs/76e7210be18dada9331ab2c4e0f6e1b89db25fa0/--/src/com/amazon/awshammerstoneexposedrestfulservicelambda/constant/Constant.java#L66-L70
 * Coral Model: https://code.amazon.com/packages/AWSHammerstoneExposedRESTfulServiceLambdaModel/blobs/b4777df9bee4f2e3487d91b9cc645b0942384b04/--/model/Datatypes.xml#L634-L645
 */
export const S3CredentialType = {
  IAM: 'IAM',
  ODIN: 'Odin (deprecated)',
} as const;
