import React from 'react';
import { dateToApiString, dateToUTCString, timeRelativeToNow } from 'src/commons';
import { DateLike } from 'src/interfaces/reactInterfaces';
import { CopySpan } from './CopySpan';
import { useDispatch } from 'react-redux';
import { actions, usePreferences } from 'src/data/redux';

/** Displays the given time as either an absolute datetime or a relative datetime, which can be toggled by the user clicking the span.
 * Additionally, a user can copy the API-formatted time string by clicking the included copy button */
export function ToggleableDatetime(props: { children: DateLike }) {
  // const [isAbsolute, setIsAbsolute] = useState(true);
  const dispatch = useDispatch();
  const { isAbsolute } = usePreferences('datetime');

  return (
    <CopySpan toCopy={dateToApiString(dateToUTCString(props.children))}>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() =>
          dispatch(actions.user.updatePreferences({ key: 'datetime', value: { isAbsolute: !isAbsolute } }))
        }
      >
        {isAbsolute ? dateToUTCString(props.children) : timeRelativeToNow(props.children)}
      </span>
    </CopySpan>
  );
}
