import { GetInstanceInfoByInstanceIdRequest } from '@amzn/aws-hammerstone-exposed-restful-service-typescript-client/clients/hammerstoneexposedrestfulservicelambda';
import { Box, Header, SpaceBetween, Tabs, TextFilter, Toggle } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { fetchActivityInfo, fetchInstanceById } from '../../data/api/fetchFromAPI';
import InstanceContainerLayout from './InstanceContainerLayout';
import FormattedLogs from './FormattedLogs';
import Skeleton from 'react-loading-skeleton';
import PAGES from '../../nav/Pages';
import { urlSearchString } from '../../nav/navHelper';
import { RefreshButton, HammerstoneAppLayout } from '../helpers';
import { useGroupName, useInstance } from 'src/data/redux';
import { useAddInstanceToRecentPages } from 'src/commons';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import RerunExecutionInstanceButton from './RerunExecutionInstanceButton';

// Loading bars while fetching logs
function LogsLoadingSkeleton() {
  return (
    <SpaceBetween size="l">
      {React.Children.toArray(
        Array(5).fill(
          <Box>
            <Skeleton height="1.1em" />
            <Skeleton height="1.1em" />
            <Skeleton height="1.1em" />
          </Box>,
        ),
      )}
    </SpaceBetween>
  );
}

export default function ViewInstance() {
  const { activityId, instanceId } = useParams();
  const groupName = useGroupName();
  const navigate = useNavigate();

  const { data: instance, fetching } = useInstance(instanceId);
  const loading = fetching !== false || !instance;

  //Whether to display a formatted version of the logs, or to display their raw text
  const [rawToggle, setRawToggle] = useState(false);
  const RawToggle = () => (
    <Box float="right">
      <Toggle checked={rawToggle} onChange={({ detail }) => setRawToggle(detail.checked)}>
        Display raw logs
      </Toggle>
    </Box>
  );

  // Whether to hide the directory and line information of the logged file
  // e.g. "/apollo/env/awsdwetl-workerservice/ruby2.3.x/lib/ruby/gems/2.3.0/gems/aws-sdk-core-3.130.0/lib/seahorse/client/net_http/handler.rb at line 38:"
  const [pathToggle, setPathToggle] = useState(false);
  const PathToggle = () => (
    <Box float="right">
      <Toggle checked={pathToggle} onChange={({ detail }) => setPathToggle(detail.checked)} disabled={rawToggle}>
        Display path
      </Toggle>
    </Box>
  );

  // Whether to hide the timestamp for log lines that have them
  const [timestampToggle, setTimestampToggle] = useState(false);
  const TimestampToggle = () => (
    <Box float="right">
      <Toggle
        checked={timestampToggle}
        onChange={({ detail }) => setTimestampToggle(detail.checked)}
        disabled={rawToggle}
      >
        Display timestamp
      </Toggle>
    </Box>
  );

  // Filter logs based on user input
  const [workerFilteringText, setWorkerFilteringText] = React.useState('');
  const [rmsFilteringText, setRmsFilteringText] = React.useState('');

  const fetchInput: GetInstanceInfoByInstanceIdRequest = {
    instanceId,
    groupName,
    activityId: parseInt(activityId),
  };

  useEffect(() => {
    //Refresh the instance upon mounting the component
    fetchInstanceById(fetchInput);
    //Also fetches activity and to get pipeline name for breadcrumbs, and faster loading thru the breadcrumbs
    fetchActivityInfo({ activityId: parseInt(activityId), groupName });
  }, [groupName]);

  useAddInstanceToRecentPages(activityId, instanceId, groupName);

  return (
    <HammerstoneAppLayout {...PAGES.VIEW_INSTANCE.pageLayoutProps({ instanceId, activityId })}>
      <SpaceBetween size="xl">
        <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <RefreshButton
                refreshing={loading}
                onRefresh={() => {
                  fetchInstanceById(fetchInput, true);
                }}
              />
              <RerunExecutionInstanceButton
                {...{ activityId, instanceId }}
                onSuccess={(output) => {
                  fetchActivityInfo({ activityId: parseInt(activityId), groupName }, true);

                  const newInstanceId = output?.resource?.instanceId;
                  if (newInstanceId) {
                    navigate({
                      pathname: PAGES.VIEW_INSTANCE.path
                        .replace(':instanceId', newInstanceId)
                        .replace(':activityId', activityId),
                      search: urlSearchString({ groupName }),
                    });
                  }
                }}
              />
            </SpaceBetween>
          }
        >
          {loading ? <span className="missing-data">loading instance ...</span> : instanceId}
        </Header>

        <Tabs
          tabs={[
            {
              label: 'Overview',
              id: 'overview',
              content: (
                <InstanceContainerLayout
                  instanceId={instanceId}
                  mode={loading ? DisplayMode.Loading : DisplayMode.View}
                />
              ),
            },
            {
              label: 'Worker Service logs',
              id: 'ws_logs',
              content: (
                <SpaceBetween direction="vertical" size="m">
                  <TextFilter
                    filteringText={workerFilteringText}
                    filteringPlaceholder="Filter logs with terms separated by spaces"
                    filteringAriaLabel="Filter logs"
                    onChange={({ detail }) => setWorkerFilteringText(detail.filteringText)}
                  />
                  <SpaceBetween direction="horizontal" size="m">
                    <RawToggle />
                    <PathToggle />
                    <TimestampToggle />
                    {/* TODO: add default filter checkboxes for warning, error, info */}
                  </SpaceBetween>
                  {loading ? (
                    <LogsLoadingSkeleton />
                  ) : (
                    <FormattedLogs
                      logsList={instance.workerServiceLogsList}
                      filterText={workerFilteringText}
                      rawToggle={rawToggle}
                      pathToggle={pathToggle}
                      timestampToggle={timestampToggle}
                    />
                  )}
                </SpaceBetween>
              ),
            },
            {
              label: 'RMS logs',
              id: 'rms_logs',
              content: (
                <SpaceBetween direction="vertical" size="m">
                  <TextFilter
                    filteringText={rmsFilteringText}
                    filteringPlaceholder="Filter logs with terms separated by spaces"
                    filteringAriaLabel="Filter logs"
                    onChange={({ detail }) => setRmsFilteringText(detail.filteringText)}
                  />
                  <SpaceBetween direction="horizontal" size="m">
                    <RawToggle />
                    <PathToggle />
                    <TimestampToggle />
                  </SpaceBetween>
                  {loading ? (
                    <LogsLoadingSkeleton />
                  ) : (
                    <FormattedLogs
                      logsList={instance.rmsLogsList}
                      filterText={rmsFilteringText}
                      rawToggle={rawToggle}
                      pathToggle={pathToggle}
                      timestampToggle={timestampToggle}
                    />
                  )}
                </SpaceBetween>
              ),
            },
          ]}
        />
      </SpaceBetween>
    </HammerstoneAppLayout>
  );
}
