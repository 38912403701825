import { Box, Icon, Select } from '@amzn/awsui-components-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { actions, useGroupName, useGroupSelectorDisabled, useHammerstoneGroups } from 'src/data/redux';

export function HammerstoneGroupSelector() {
  const dispatch = useDispatch();
  const groupName = useGroupName();
  const groupSelectorDisabled = useGroupSelectorDisabled();
  const hammerstoneGroups = useHammerstoneGroups() ?? [];

  return (
    <div id="groupSelector">
      <Box padding={{ top: 'xxs', right: 'xs' }}>
        <Icon name="group" variant={groupSelectorDisabled ? 'disabled' : 'normal'} />
      </Box>
      <Select
        selectedOption={{ value: groupName ?? 'Loading...' }}
        onChange={({ detail }) => {
          dispatch(actions.user.setGroupName(detail.selectedOption.value));
        }}
        options={hammerstoneGroups.map((value) => ({
          value,
          // TODO: Eventually stylize the Admin group?
          iconName: value === groupName ? 'group-active' : undefined,
        }))}
        filteringType="auto"
        selectedAriaLabel="Selected"
        disabled={groupSelectorDisabled}
      />
    </div>
  );
}
