import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PAGES from '../../nav/Pages';
import { HammerstoneAppLayout } from '../helpers';
import ActivityContainerLayout from './ActivityContainerLayout';
import { s3CredentialTypeSelector } from 'src/data/redux';
import ReactHookFormWrapper from '../helpers/ReactHookFormWrapper';
import { DisplayMode } from '../helpers/content/contentInterfaces';
import { useActivityForm } from './useActivityForm';

export default function CreateActivity() {
  const { form, onCancel, onValidSubmit, groupName, ActivateButton, setSearchParams } = useActivityForm(
    'create',
    undefined,
  );

  const [pipelineName, activityType] = form.watch(['activityNamespace', 'activityType']);
  const s3CredentialType = useSelector(s3CredentialTypeSelector(pipelineName));

  useEffect(() => {
    // Updates the Search Params with the new pipelineName whenever it is updated
    if (pipelineName) {
      setSearchParams((s) => {
        s.set('pipelineName', pipelineName);
        // Removes unnecessary & distracting token from URL bar (Midway)
        s.delete('id_token');
        return s;
      });
    }
  }, [pipelineName]);

  return (
    <HammerstoneAppLayout {...PAGES.CREATE_ACTIVITY.pageLayoutProps({ groupName, pipelineName })}>
      <ReactHookFormWrapper
        form={form}
        header={`Create activity`}
        onCancel={onCancel}
        onValidSubmit={onValidSubmit}
        // Do not allow submission if the user attempts to create a new activity in an Odin pipeline
        disabled={s3CredentialType === 'ODIN'}
        actions={ActivateButton}
      >
        <ActivityContainerLayout mode={DisplayMode.Edit} activityId={''} activityType={activityType} />
      </ReactHookFormWrapper>
    </HammerstoneAppLayout>
  );
}
