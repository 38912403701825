import { AwsRumConfig } from 'aws-rum-web';

// TODO: replace with your personal AWS account when trying to test locally
const PERSONAL_DEV_ACCOUNT = '266270031225';
const BETA_ENDPOINT = 'beta.hammerstone.us-east-1.data-tools.aws.a2z.com'
const GAMMA_ENDPOINT = 'gamma.hammerstone.us-east-1.data-tools.aws.a2z.com'
const PROD_ENDPOINT = 'hammerstone.us-east-1.data-tools.aws.a2z.com'

// Set the same number of standard retries as Midway library
// https://tiny.amazon.com/16bxbt95k/codeamazpackMidwblobef29src
export const AWS_CREDS_NUM_RETRIES = 3;

export const HAMMERSTONE_ADMIN_GROUP = 'AWS-DW-PRIMARY';

/**
 * Get current URL domain of the endpoint
 *
 * @returns {string} Endpoint domain of the service or 'devDesktop' for local cloud desktops
 */
export const getServiceDomain = (): string => {
  const domain = window.location.hostname;

  // Account for Dev-desktop endpoints
  if (domain.includes('.corp.amazon.com')) {
    return 'devDesktop';
  }

  return domain;
};

/**
 * Get region from URL
 * e.g. https://dev-dsk-sjzhang-2a-903d4059.us-west-2.amazon.com
 * e.g. https://beta.hammerstone.us-east-1.data-tools.aws.a2z.com
 * e.g. https://hammerstone.us-east-1.data-tools.aws.a2z.com
 *
 * @returns {string} Region e.g. us-east-1
 */
export const getServiceRegion = (): string => {
  const domain = window.location.hostname;
  const subdomains = domain.split('.');

  // this should never happen, unless Route53 settings are messed up
  if (subdomains.length <= 0) {
    throw URIError("Domain is not loaded?! Check browser's endpoint settings");
  }

  // TODO: refactor using regex
  const region = subdomains[1];
  if (region === 'hammerstone') {
    // Non-prod endpoints are longer b/c no beta/gamma prefix https://beta.hammerstone.us-east-1.data-tools.aws.a2z.com
    return subdomains[2];
  } else if (domain.includes('.corp.amazon.com')) {
    // Dev-Desktop, so just ask us-east-1 STS/Midway region for help
    return 'us-east-1';
  }
  return region;
};

// Used for various headers
export const WEBSITE_NAME = 'Hammerstone';

// Used for to redirect customers to old Hammerstone experience if the new page is not built yet
const OLD_HAMMERSTONE_HREF: {
  [key: string]: {
    hsEndpoint: string;
    ptEndpoint: string;
  };
} = {
  // Dev Desktops always point to old Gamma
  'devDesktop': {
    hsEndpoint: 'https://hammerstonegamma.amazon.com',
    ptEndpoint: 'https://hammerstone-permissions-gamma-iad.iad.proxy.amazon.com',
  },

  // Beta points to legacy Gamma, since old Beta is broken
  [BETA_ENDPOINT]: {
    hsEndpoint: 'https://hammerstonegamma.amazon.com',
    ptEndpoint: 'https://hammerstone-permissions-gamma-iad.iad.proxy.amazon.com',
  },

  // New Gamma points to old Gamma
  [GAMMA_ENDPOINT]: {
    hsEndpoint: 'https://hammerstonegamma.amazon.com',
    ptEndpoint: 'https://hammerstone-permissions-gamma-iad.iad.proxy.amazon.com',
  },

  // IAD Prod
  [PROD_ENDPOINT]: {
    hsEndpoint: 'https://hammerstone.amazon.com',
    ptEndpoint: 'https://hammerstone-permissions-iad.iad.proxy.amazon.com',
  },

  // TODO: add other regions
};

export const getOldHammerstoneEndpoint = (): any => {
  const domain: string = getServiceDomain();
  const oldDomain = OLD_HAMMERSTONE_HREF[domain].hsEndpoint;
  return oldDomain;
};

export const getOldPermissionsToolEndpoint = (): any => {
  const domain: string = getServiceDomain();
  const oldDomain = OLD_HAMMERSTONE_HREF[domain].ptEndpoint;
  return oldDomain;
};

// Hammerstone Exposed RESTful Service endpoints
// TODO: Replace dev desktop and Prod configs with the actual configs
// TODO: Look into potential solution of calling AWS CLI to get this information.
// The CDK must be deployed through a stage first before you can fill out the information in these configs.
// After the CDK is deployed, go to Cloudwatch RUM on the AWS console and click on the monitor that was 
// created by the CDK. Click on "Configuration" and then click on "Javascript Snippet" on the sidebar.
// The Javascript snippet will contain an application id, application version, application region, and 
// AwsRumConfig, which you can copy into the config below. 
const AWS_RUM_CONFIG: {
  [key: string]: {
  applicationId: String;
  applicationVersion: String;
  applicationRegion: String;
  awsRumConfig: AwsRumConfig;
  }
} = {
  // Dev Desktops
  'devDesktop': {
    applicationId: "3386ab2f-8270-4fa1-bf92-9c487a9da43a",
    applicationVersion: "1.0.0",
    applicationRegion: "us-east-1",
    awsRumConfig: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::734789510895:role/RUM-Monitor-us-east-1-734789510895-1332921156761-Unauth",
      identityPoolId: "us-east-1:1dfc82f9-d6d5-4aab-80bd-438ce62bd6b1",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    }
  }, 
  // Beta Endpoints
  [BETA_ENDPOINT]: {
    applicationId: "0576c7ae-cf26-4c01-9362-caa7cd9f4f1e",
    applicationVersion: "1.0.0",
    applicationRegion: "us-east-1",
    awsRumConfig: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::734789510895:role/RUM-Monitor-us-east-1-cognitoUnauthenticatedRole",
      identityPoolId: "us-east-1:da15ae0f-8336-4525-9622-eecb8ed62328",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors","performance","http"],
      allowCookies: true,
      enableXRay: true
    }
  }, 
  // Gamma Endpoints
  [GAMMA_ENDPOINT]: {
    applicationId: "2de12002-e4be-4c4a-9ee2-7c4e8efe19af",
    applicationVersion: "1.0.0",
    applicationRegion: "us-east-1",
    awsRumConfig: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::661504521297:role/RUM-Monitor-us-east-1-cognitoUnauthenticatedRole",
      identityPoolId: "us-east-1:84c92a3a-ec3b-4dc4-ab09-a6369a788dc8",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors","performance","http"],
      allowCookies: true,
      enableXRay: true
    }
  }, 
  // Prod Endpoints
  [PROD_ENDPOINT]: {
    applicationId: "511b863e-01d2-4f85-a58d-1432cfe1fdde",
    applicationVersion: "1.0.0",
    applicationRegion: "us-east-1",
    awsRumConfig: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::057244456334:role/RUM-Monitor-us-east-1-cognitoUnauthenticatedRole",
      identityPoolId: "us-east-1:32573083-d841-412f-89ab-c716185ff3cf",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors","performance","http"],
      allowCookies: true,
      enableXRay: true
    }
  }, 
  // TODO: add other regions
};

// Hammerstone Exposed RESTful Service endpoints
const HS_API_CLIENT_CONFIG: {
  [key: string]: {
    endpoint: string;
    region: string;
    iamRolePartition: string;
    iamRoleAccount: string;
  };
} = {
  // Dev Desktops
  'devDesktop': {
    endpoint: 'https://beta.us-east-1.api.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: PERSONAL_DEV_ACCOUNT,
  },

  // Beta Endpoints
  [BETA_ENDPOINT]: {
    endpoint: 'https://beta.us-east-1.api.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: '734789510895',
  },

  // Gamma/Preprod Endpoints
  [GAMMA_ENDPOINT]: {
    endpoint: 'https://gamma.us-east-1.api.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: '661504521297',
  },

  // Prod Endpoints
  [PROD_ENDPOINT]: {
    endpoint: 'https://us-east-1.api.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: '057244456334',
  },

  // TODO: add other regions
};

export const REPO_SERVICE_CLIENT_CONFIG: {
  [key: string]: {
    endpoint: string;
    region: string;
    iamRolePartition: string;
    iamRoleAccount: string;
  };
} = {
  // Dev Desktops
  'devDesktop': {
    endpoint: 'https://beta.repository-service.us-east-1.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: PERSONAL_DEV_ACCOUNT,
  },

  // Beta Endpoints
  [BETA_ENDPOINT]: {
    endpoint: 'https://beta.repository-service.us-east-1.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: '734789510895',
  },

  // Gamma/Preprod Endpoints
  [GAMMA_ENDPOINT]: {
    endpoint: 'https://gamma.repository-service.us-east-1.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: '661504521297',
  },

  // Prod Endpoints
  [PROD_ENDPOINT]: {
    endpoint: 'https://repository-service.us-east-1.data-tools.aws.a2z.com',
    region: 'us-east-1',
    iamRolePartition: 'aws',
    iamRoleAccount: '057244456334',
  },
};

export const getServicePartition = (): string => {
  const domain = getServiceDomain();
  return HS_API_CLIENT_CONFIG[domain]?.iamRolePartition;
};

export const getHSApiConfig = (): any => {
  const domain: string = getServiceDomain();
  return HS_API_CLIENT_CONFIG[domain];
};

export const getRepoServiceClientConfig = (): any => {
  const domain: string = getServiceDomain();
  return REPO_SERVICE_CLIENT_CONFIG[domain];
};

export const getAwsRumConfig = (): any => {
  const domain: string = getServiceDomain();
  return AWS_RUM_CONFIG[domain];
}

export const getHsApiClientIamRoleArn = (partition: string, account: string): string => {
  // Role name specifed in CDK's API stack
  // https://code.amazon.com/packages/AWSHammerstoneUICDK/blobs/8f330b0573d078f559e3ff816bb45c9bc8ee803c/--/lib/apiAuthStack.ts#L63
  return `arn:${partition}:iam::${account}:role/HammerstoneAPITempCreds`;
};

export const getRepositoryServiceClientIamRoleArn = (partition: string, account: string): string => {
  // Role name specified in CDK's API stack
  // https://code.amazon.com/packages/AWSHammerstoneUICDK/blobs/8f330b0573d078f559e3ff816bb45c9bc8ee803c/--/lib/apiAuthStack.ts#L72
  return `arn:${partition}:iam::${account}:role/HammerstoneRepoTempCredsRole`;
};